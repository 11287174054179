
@media all and (max-width: 1730px)  {
	.PopularFeatures{
		display: none !important;
	}
}
@media all and (max-width: 1430px)  {
	/*#Websites8{
		display: block !important;
		.ipadSliderContainerOuter,
		.col-6,
		.col-6{
			width: 100% !important;
			clear: both;
			@include transform(translateX(0px) !important);
		}
		.col-7{
			    padding-bottom: 40px;
		}
		.col-5{
			padding: 0px 20px !important;
		}
	}*/
	#SideNav{
		display: none !important;
	}
	.WebsitesPage #Websites3.activeIn .section1 .right img{
    		max-width: 100% !important;
	}
	.ipadSliderContainerOuter.match4,
	.CenterItems.match4{
		min-height: 0px !important;
	}
/*
	.match4.CenterItems{
		clear: both;
		width: 100% !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}*/


}

@media all and (max-width: 1030px)  {
	*{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
}

@media all and (max-width: 1308px)  {
	#Home3 .flex{
		display: block !important;
	}
	.PortfolioTitle{
		width: 100% !important;
		clear: both;
		opacity: 1 !important;
		padding: 30px 20px !important;
		@include transition(all 0ms 0ms !important);
		@include transform( translateX(0%) !important);
		.SliderTitle{
			font-size: 1.3em !important;
		}
	}


	#PortfolioButton {
   	 position: absolute;
	    @include transform(translateY(-50%) translateX(0%) !important);
	    right: 20px !important;
	    bottom: auto !important;
	    width: 150px !important;
	    left: auto !important;
	    top: 50% !important;
	}
	#Home3 .col-10{
		width: 100% !important;
	}
}
@media all and (max-width: 1280px)  {
	footer .footerContact {
	    font-size: 1em !important;
	}
	#Websites8{
		display: block !important;
		.ipadSliderContainerOuter,
		.col-6,
		.col-6{
			width: 100% !important;
			clear: both;
			@include transform(translateX(0px) !important);
		}
		.col-6{
			    padding-bottom: 40px;
		}
		.col-6{
			padding: 0px 20px !important;
		}
	}

/*	.match4.CenterItems{
		clear: both;
		width: 100% !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}*/
}
@media all and (max-width: 1100px)  {
	#Home5 .LinkBox {
	    opacity: 1 !important;
	    width: 50%;
	    padding-bottom: 50%;
	    @include transition(all 0ms !important);
	}
	#Home5 .LinkBox:first-of-type{
		width: 100% !important;	
	}

	#Portfolio1{
		display: block !important;
	}
	.portfolioLeft{
		clear: both;
		width: 100%;
	}

	.filters button {
		width: auto;
		margin: 10px;
	    display: inline-block;
	}
	.portfoliosideLinks{
		display: none !important;
	}
	.filters{
		@include transition(all 0ms !important);
		margin-bottom: 0px !important;
	}
	.articleInner,
	.PortfolioItemContainerOuter{
		width: 100% !important;
		clear: both;
	}
	.hideLinksDesktop{
		display: block !important;
	}
	.BlogPage .PortfolioItemContainerOuter {
    	padding-top: 0px !important;
	}
	.BlogPage .portfolioLeft, .PortfolioPage .portfolioLeft {
	    padding: 20px 2.5% 40px 2.5% !important;
	}
}

@media all and (max-width: 1230px)  {
	#MenuIcon{
		display: block;
	}

	#DesktopNavigation{
		display: none !important;
	}
	.topLinks {
	    right: 0px;
	}

	header #Logo {
		left: 0px !important;
	    right: auto;
	}
	.scrollNav .mobMenu{
	    top: 14px;
	}
	.scrollNav .headerDecoration{
	 	opacity: 0 !important;
	 }
	 .subTitle{
	 	padding-left: 20px;
	 	padding-right: 20px;
	 }
}


@media all and (max-width: 1036px)  {
	.swipeButton{
		/*display: inline-block !important;*/
	display: none !important;
	}
	.ourTeamSection{
		clear: both;
		width: 100% !important;
		min-height: 0px !important;
		padding-bottom: 20px !important;
		.pageTitle{
		    padding-right: 85px !important;
		}
	}


	#AboutButton {
	    right: 40px;
	    left: auto;
	    bottom: auto;
	    top:32%;
	    position: absolute;
	    @include transform( translateX(50%) translateY(-50%) scale(1) !important);
	}
}
@media all and (max-width: 1024px)  {
	.WebsitesContent .match,
	.CenterItems.match,
	.CenterItems.match4,
	.CenterItems.match3,
	.CenterItems.match2{
		min-height: 0px !important;
	}
	.MarketingPage  .col-12.clear.match{
		min-height: 0px !important;
	}
	.MarketingPage #Marketing6 .ContentSection, .MarketingPage #Marketing2 .ContentSection, .MarketingPage #Marketing1 .ContentSection{
		padding-bottom: 0px !important;
	}

	#Websites5  .match{
		min-height: 0px !important;
	}

	#Websites5 .ContentSection{
		padding-bottom: 30px !important;
	}
	
	#EstateAgents1,
	.fixedBG,
	#SchoolBranding4{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}


	.ImageSectionLeft,
	.ImageSectionRight,
	.TextSection,
	#SchoolBranding7,
	#SchoolBranding4,
	#SchoolBranding2,
	#Marketing6{
		.col-6{
			opacity: 1 !important;
			width: 100%;
			clear: both;
			text-align: center;
			@include transform(translate(0%, 0%) !important);
		}
	}
	#SchoolBranding2 .match{
		min-height: 0px !important;
	}
	#SchoolBranding2 .match:first-of-type{
		margin-bottom: 20px !important;
	}
	#SchoolBranding4{
		.col-6{
			padding: 0px 20px !important;
		}
	}
	.SchoolBrandingPage #SchoolBranding4 .contentButton {
		margin-bottom: 50px;
	    position: relative;
	    left: auto;
	}
	.section7 .left{
		padding-right: 0px !important;
	}
	#SchoolBranding4{
		.flex{
			display: block !important;
		}
	}

	#Websites7{
		.ContentSection{
			text-align: center !important;
		}
	}
	.subTitle {
	    font-size: 1.3em !important;
	}
	.WebsitesPage{
		.section1{
			padding-left: 20px;
			padding-right: 20px;
		}
		.ContentSection.parallax{
			opacity: 1 !important;
			@include transform(translate(0%,0%) !important);
			clear: both;
			width: 100% !important;
			text-align: center !important;
			padding: 0px !important;
		}
	}
	#Websites6{
		.ContentSection{
			padding-right: 0px !important;
		}
	}

	.WebsitesPage #Websites5 .ContentSection #ContactUsButton,
	#WebsitesButton1{
	    margin-left: 0px !important; 
	    @include transform(translateX(0%) !important);
	}



	.right.parallax img {
   		max-width: 300px;
	}
	.banner .parallax{
		margin-top: 0px !important;
	}
	.ProspectusPage #Prospectus3 .bg{
		margin-top: 0px !important;
	    position: relative;
	    top: auto;
	    left: auto;
	    width: 100%;
	    clear: both;
	    height: 300px !important;
	}
	.ProspectusPage #Prospectus3 .bg,
	#Home6 .bg.parallax{
		margin-top: 0px !important;
	}
	.bannerText.parallax{
		margin-bottom: 0px !important;
		bottom: 190px !important;
	}

	.right.parallax img{
			margin-top: 40px;
	}
	.right.parallax,
	.left.parallax{
		opacity: 1 !important;
		@include transform(translate(0%,0%) !important);
		clear: both;
		width: 100% !important;
		text-align: center !important;
		padding: 0px !important;
	}
	.ProspectusPage .activeIn#Prospectus3 .infoText,
	.ProspectusPage #Prospectus3 .infoText{
		float: left;
		text-align: center;
		padding: 50px 20px 50px 20px !important;
	    width: 100% !important;
	    bottom: 0px !important;
	    position: relative;
	    clear: both;
	    @include transform(translate(0%,0%) !important);
	    left: auto !important;
	}
	.ProspectusPage #Prospectus3{
		min-height: 0px !important;
	}
	.bannerTitle {
	    font-size: 1.2em !important;
	}
	.ProspectusPage .section1 .left .button,
	.ProspectusPage .section2 .right .button {
	    font-size: 1.3em;
	    margin-left: 0px !important;
	    @include transform(translateX(-0%) !important);
	}

	.ImageSectionLeft{
		.innerCenter.flex{
			display: block !important;
		}
	}
	.branding2,
	.TextSection,
	.ImageSectionRight,
	.ImageSectionLeft{ 
		.left{
			padding-left: 20px !important;
			padding-right: 20px !important;
		}.right{
			padding-left: 20px !important;
			padding-right: 20px !important;
		}
	}

	
		.innerCenter * li{	
			list-style-position: inside !important;
		}

}

@media all and (max-width: 1014px)  {
	#Home6 .centerText {
	    opacity: 1 !important;
	    width: 100%;
	    @include transition(all 0ms !important);
	    text-align: center;
	    bottom: 0px !important;
	}

	#Home6 {
	    min-height: 400px !important;
	}
}
@media all and (max-width: 1000px)  {
	.imageLeft{
		display: none;
	}
	.aboutUsTitle{
		margin-left: 0px !important;
		clear: both;
		width: 100%;
		text-align: center;
	}
	.aboutUsRight{
		clear: both;
		width: 100%;
	}
	#Portfolio1{

		.col-4,
		.col-8{
			width: 100% !important;
			clear: both;
			padding: 0px;
		}
	}
}
@media all and (max-width: 990px)  {
	.WebsitesPage #Websites4 .subHeading {
	    font-size: 1.3em !important;
	}
	.pageTitle {
	    font-size: 2em !important;
	}

	#Home2 .HomeService{
		width: 50%;
	}

	#TestimonialsSlider .sliderSection{
		width: 100% !important;
		clear: both !important;
	}
	#Home4 .pageTitle{
		padding-right: 90px !important;
	}
	#FooterButton{
		display: none;
	}
	.up{
		display: none !important;
	}
	footer{
		text-align: center !important;
	}
	.MarketingPage #Marketing5 .col-2 {
	    opacity: 1 !important;
	    width: 33.33%;
	    padding-bottom: 33.33%;
	    @include transition(all 0ms !important);
	}
	#aboutUsSLiderContainer,
	#TestimonialsSliderContainer{
		.bx-controls{
			position: relative !important;
			top: auto !important;
		}
	}
}
@media all and (max-width: 900px)  {
	#SideNav{
		display: none !important;
	}

	.PortfolioTitle{
		@include transition(all 0ms 0ms !important);
		@include transform(translateX(0%) translateY(0%) !important);
		opacity: 1 !important;
	}
	.image{
		@include transition(all 0ms 0ms !important);
		@include transform(translateX(0%) translateY(0%) !important);
		width: 50% !important;
		padding-bottom: 50% !important;
		opacity: 1 !important;
	}

	.WebsitesPage .ipadSliderContainerInner {
	    margin: 0 auto;
	    width: 500px;
	}
	.WebsitesPage .ipadSlider {
	    position: absolute;
	    top: 35px;
	    left: 42px;
	    width: 414px;
	    height: 313px;
	}

	#Home3 .group3{
		opacity: 1 !important;
	}
	.clearPortfolioMid{
		height: auto !important;
		min-height: 0px !important;
		width: 100% !important;
		clear: both !important;
		padding-left: 0px !important;
		padding-bottom: 40px !important;
		padding-right: 0px !important;
	}
	.PageControls{
		padding-left: 0px !important;
	}
	#DragSlider {
	    position: absolute;
	    top: 35px;
	    left: 42px;
	    min-width: 0px !important;
	    width: 415px !important;
	    overflow: hidden;
	    height: 0px;
	    min-height: 313px !important;
	}
}
@media all and (max-width: 848px)  {

	.portfolioItemClear{
		clear: both !important;
		width: 100% !important;
		height: auto !important;
		min-height: 0px !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
		margin-bottom: 30px;
	}

	.caseBottomRight{
		.tar{text-align: center !important;}
		display: block;
		.CasePageImageTwo{
			position: relative;
			left: auto !important;
			width: 80% !important;
		    margin: 0 auto;
		    display: block;
		}
		.button{
			position: relative;
		}

		.col-6{
			width: 100% !important;
			clear: both;
		}
	}

	.CasePage .caseBottomRight .button{
		position: relative !important;
		right: auto !important;
		bottom: auto !important;
		-webkit-transform: translateX(0%) !important;
	    -moz-transform: translateX(0%) !important;
	    -ms-transform: translateX(0%) !important;
	    transform: translateX(0%) !important;
	    margin-left: 0px !important;
	}

	.ContactForm .col-6{
		clear: both !important;
		width: 100% !important;
	}
	.ContactForm .col-6:first-of-type{
		margin-bottom: 40px !important;
	}
	#Prospectus3{
		.bg{
			display: none !important;
		}
	}

	#Prospectus3 .infoText {
	    position: relative;
	    float: left;
	    left: auto !important;
	    bottom: auto !important;
	    opacity: 1 !important;
	    clear: both;
	    -webkit-transform: translateX(0%) translateY(0%) !important;
    	-moz-transform: translateX(0%) translateY(0%) !important;
   		-ms-transform: translateX(0%) translateY(0%) !important;
    	transform: translateX(0%) translateY(0%) !important;
	}
	#Prospectus3{
		min-height: 0px !important;
	}
	.WebsitesPage #Websites7 .centerWhite {
	    padding: 0px 0px;
	    width: 100%;
	    max-width: 100%;
	}
	#Websites7{
		background-image: none !important;
	}
	.WebsitesPage #Websites7 {
	    padding: 0px 0px !important;
	    min-height: auto !important; 
	}
	.pageLink{
		width: 50%;
		height: 160px;
		margin: 20px 0px;
		border-right: none !important;
		opacity: 1 !important;
		@include transition(all 0ms !important);
	}
	.AboutBlock {
	    clear: both;
	    width: 100% !important;
	    height: auto !important;
	    min-height: 0px !important;
	    @include transition(all 0ms !important);
	}

	#AboutUs1{
		.topSection{
			.CenterItems{
				display: block !important;
				.col-6{
					width: 100%;
					clear: both;
					text-align: center;
				}
			}
		}
	}
	#FundraisingSection{
		.content{
		 	padding-right: 0px !important;
		}
		.col-6{
			padding: 0px !important;
			clear: both;
			width: 100% !important;
		}
	}
	.toggleContentSection{
		padding: 20px 0px !important;
		clear: both;
		width: 100% !important;
		text-align: center !important;
	}
}
@media all and (max-width: 768px)  {
	.clearMid{
		clear: both;
		width: 100% !important;
	}

	.tacMid{
		text-align: center !important;
	}
}

@media all and (max-width: 680px)  {
	.PopularFeatures{
		display: none;
	}
	.filters button {
	    font-size: 1.1em !important;
	    padding: 10px 20px 10px 7px;
	}
	.filters{
		text-align: left !important;
	}
	.pageTitle.PortfolioGroup1 {
	    padding-left: 4px !important;
	}

	
	.AboutUsPage{
		.bx-default-pager {
			text-align: center;
		}
		#aboutUsSLider{
			.col-8,
			.col-4{
				clear: both;
				width: 100% !important;
				text-align: center;
			}
		}
		#CareersSection .content {
	   	 	font-size: 1.2em !important;
	   		padding: 0px 20px !important;
		}
	}
	

}
@media all and (max-width: 630px)  {

	#Logo{
		img {
	    max-width: 50px;
	    }
    }

	.toggleNav {
	    top: -2px !important;
	    font-size: 23px !important;
	}

	.SearchContainer{
		display: none !important;
	}
	.activeSearch .SearchContainer{
		display: none !important;
	}
	
	.toggleContentSection{
		clear: both;
		width: 100% !important;
		font-size: 1.1em !important;
		text-align: center !important;
	}
	#Prospectus4 .subHeading {
    	font-size: 1.2em !important;
	}
	.toggleContentSection:last-of-type{
		padding-top: 0px !important;
	}

	.twitterSection{
		clear: both !important;
		width:  100% !important;
	}

	.twitterSection{
		padding: 0px !important;
	}

	.twitterMatch{
		min-height: 0px !important;
	}

	#Home2 .HomeService{
		width: 100%;
		text-align: center;
	}
	#Home2 .HomeService .Text{
		min-height: 0px !important;
	}
	#Home2 {
		text-align: center;
	}
	#Home2  .Icon {
		margin: 20px auto;
	}
	.callus{
		font-size: 0.7em;
		right: 20px;
	}
	.closeMenu {
	    top: 30px;
	    left: 30px;
	}
	header #Logo {
	    width: 150px !important;
	}
	header .innerHeader {
	    min-height: 75px;
	}

	header .topLinks {
	    margin-top: -40px !important;
	    opacity: 0 !important;
	}

	#LinksIcon{
		display: block !important;
	}

	.rightBannerLinks{
		display: none !important;
	}
	.bannerText span {
	    font-size: 1.3em !important;
	}

	.scrollNav header #Logo {
    	top: 50%;
	}

	.mobMenu{
	    top: 14px;
	}

	.headerDecoration{
		display: none !important;
	}

	header {
	    padding: 0px 20px !important;
	}
	#LinksMenu .InnerLinkMenu .quickLinks a,
	#MobileNavigation ul li a {
	    font-size: 1em !important;
	    padding: 3px 34px 2px 34px !important;
	    &:after {
          content: '';
          position: absolute;
          display: block;
          border-right: 1px solid #bdbbbb;
          border-bottom: 1px solid #bdbbbb;
          height: 5px;
          bottom: 0px;
          width: 5px;
          right: 0px;
          opacity: 0;
        }
        &:before {
          content: '';
          position: absolute;
          display: block;
          border-left: 1px solid #bdbbbb;
          border-top: 1px solid #bdbbbb;
          height: 5px;
          top: 0px;
          width: 5px;
          left: 0px;
          opacity: 0;
        }
	}

	/*** Stop Lazy Loading ***/
	/*** Home ***/
	#Home2 .HomeService {
	    -webkit-transform: scale(1);
	    -moz-transform: scale(1);
	    -ms-transform: scale(1);
	    transform: scale(1);
	    opacity: 1 !important;
	}
	#Home2 .pageTitle {
	    opacity: 1 !important;
	}
	#Home4 #TestimonialsSliderContainer {
	    opacity: 1 !important;
	    -webkit-transform: translateY(0%) !important;
	    -moz-transform: translateY(0%) !important;
	    -ms-transform: translateY(0%) !important;
	    transform: translateY(0%) !important;
	}
	#Home4 .topFade {
	    opacity: 1 !important;
	}
	#Home7 .ourTeamSection:last-of-type, #Home7 .ourTeamSection:first-of-type {
	    opacity: 1 !important;
	    -webkit-transform: translateX(0%) !important;
	    -moz-transform: translateX(0%) !important;
	    -ms-transform: translateX(0%) !important;
	    transform: translateX(0%) !important; 
	}
	#Home8 {
	    background-color: #464647 !important;
	}
	#Home8 .pageTitle {
	    opacity: 1 !important;
	}
	/*** Websites ***/
	.WebsitesPage #Websites1 img.WebsitesGroup1 {
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	}
	.WebsitesPage #Websites1 .WebsitesGroup1 {
	    opacity: 1 !important;
	}

	.WebsitesPage #Websites3 .WebsitesGroup3 {
	    opacity: 1 !important;
	}
	.WebsitesPage #Websites3 .WebsitesGroup3 {
	    opacity: 1 !important;
	}
	.WebsitesPage #Websites3 .section1 .right img {
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	    max-width: 100% !important;
	}
	.WebsitesPage #Websites1 .WebsitesGroup1.pageTitle {
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	.WebsitesPage #Websites1 .WebsitesGroup1.WebsitesContent {
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	/*** Prospectus ***/
	.ProspectusPage #Prospectus1 .ProspectusGroup1.pageTitle {
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	.ProspectusPage #Prospectus1 .ProspectusGroup1 {
	    opacity: 1 !important;
	}
	.ProspectusPage #Prospectus1 .ProspectusGroup1.prospectusContent {
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	.ProspectusPage #Prospectus2 img.ProspectusGroup2 {
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	}
	.ProspectusPage #Prospectus2 .ProspectusGroup2 {
	    opacity: 1 !important;
	}
	.ProspectusPage #Prospectus5 .ProspectusGroup3.pageTitle {
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	.ProspectusPage #Prospectus5 .ProspectusGroup3.prospectusContent {
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	.ProspectusPage #Prospectus5 img.ProspectusGroup3 {
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	}
	.ProspectusPage #Prospectus3 .infoText, .ProspectusPage #Prospectus3 .infoText {
	    float: left;
	    text-align: center;
	    padding: 50px 20px 50px 20px !important;
	    width: 100% !important;
	    bottom: 0px !important;
	    position: relative;
	    clear: both;
	    -webkit-transform: translate(0%, 0%) !important;
	    -moz-transform: translate(0%, 0%) !important;
	    -ms-transform: translate(0%, 0%) !important;
	    transform: translate(0%, 0%) !important;
	    left: auto !important;
	}
	.ProspectusPage #Prospectus1 img.ProspectusGroup1 {
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	}
	.ProspectusPage #Prospectus2 .ProspectusGroup2.pageTitle {
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	.ProspectusPage #Prospectus2 .ProspectusGroup2.prospectusContent {
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	.ProspectusPage #Prospectus5 .ProspectusGroup3.pageTitle {
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	.ProspectusPage #Prospectus5 .ProspectusGroup3.prospectusContent {
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	#Prospectus3 .infoText {
	    opacity: 1 !important;
	    -webkit-transform: translateX(0%) translateY(0px) !important;
	    -moz-transform: translateX(0%) translateY(0px) !important;
	    -ms-transform: translateX(0%) translateY(0px) !important;
	    transform: translateX(0%) translateY(0px) !important;
	}
	.ProspectusPage #Prospectus5 .ProspectusGroup3 {
	    opacity: 1 !important;
	}
	/*** School Branding ***/
	.SchoolBrandingPage #SchoolBranding1 .SchoolBrandingGroup1.pageTitle {
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	.SchoolBrandingPage #SchoolBranding1 .SchoolBrandingGroup1 {
	    opacity: 1 !important;
	}
	#SchoolBranding4 .SchoolBrandingGroup2, #SchoolBranding2 .SchoolBrandingGroup2 {
	    opacity: 1 !important;
	}
	#SchoolBranding4 .SchoolBrandingGroup3, #SchoolBranding2 .SchoolBrandingGroup3 {
	    opacity: 1 !important;
	}
	#SchoolBranding7 .pageTitle {
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	#SchoolBranding7 .SchoolBrandingGroup7 {
	    opacity: 1 !important;
	}
	#SchoolBranding7 .SchoolBrandingContent {
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	#SchoolBranding7 img.SchoolBrandingGroup7 {
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	}
	.SchoolBrandingPage #SchoolBranding1 .SchoolBrandingContent {
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	.SchoolBrandingPage #SchoolBranding1 img.SchoolBrandingGroup1 {
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	}
	/*** Marketing Page ***/
	#Marketing6 .titleSection.MarketingGroup6, 
	#Marketing6 .titleSection.MarketingGroup1, 
	#Marketing6 .titleSection.MarketingGroup2, 
	#Marketing1 .titleSection.MarketingGroup6,
	#Marketing1 .titleSection.MarketingGroup1, 
	#Marketing1 .titleSection.MarketingGroup2, 
	#Marketing2 .titleSection.MarketingGroup6, 
	#Marketing2 .titleSection.MarketingGroup1, 
	#Marketing2 .titleSection.MarketingGroup2 {
		-webkit-transform: translateY(0px) !important;
		-moz-transform: translateY(0px) !important;
		-ms-transform: translateY(0px) !important; 
		transform: translateY(0px) !important;
	}
	#Marketing6 .MarketingGroup2, 
	#Marketing6 .MarketingGroup6, 
	#Marketing6 .MarketingGroup1, 
	#Marketing1 .MarketingGroup2, 
	#Marketing1 .MarketingGroup6, 
	#Marketing1 .MarketingGroup1, 
	#Marketing2 .MarketingGroup2, 
	#Marketing2 .MarketingGroup6, 
	#Marketing2 .MarketingGroup1 {
		opacity: 1 !important;
	}

	#Marketing6 .ContentSection.right,
	#Marketing6 .ContentSection.left, 
	#Marketing1 .ContentSection.right, 
	#Marketing1 .ContentSection.left, 
	#Marketing2 .ContentSection.right,   
	#Marketing2 .ContentSection.left {
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	.MarketingPage #Marketing5 .col-2 {
	    opacity: 1 !important;
	}
	.MarketingPage #Marketing5 .Forwards.col-2 {
	    -webkit-transform: translateY(0px) translateX(0px) scale(1) !important;
	    -moz-transform: translateY(0px) translateX(0px) scale(1) !important;
	    -ms-transform: translateY(0px) translateX(0px) scale(1) !important;
	    transform: translateY(0px) translateX(0px) scale(1) !important;
	}
	.MarketingPage #Marketing5 .Backwards.col-2 {
	    -webkit-transform: translateY(0px) translateX(0px) scale(1) !important;
	    -moz-transform: translateY(0px) translateX(0px) scale(1) !important;
	    -ms-transform: translateY(0px) translateX(0px) scale(1) !important;
	    transform: translateY(0px) translateX(0px) scale(1) !important;
	}
	/*** Portfolio ***/
	.NewsArticle #Portfolio1 .PortfolioItemContainerOuter.PortfolioGroup1, 
	.NewsArticle #Portfolio1 .PortfolioGroup1.pageTitle, 
	.NewsHolder #Portfolio1 .PortfolioItemContainerOuter.PortfolioGroup1, 
	.NewsHolder #Portfolio1 .PortfolioGroup1.pageTitle, 
	.BlogPage #Portfolio1 .PortfolioItemContainerOuter.PortfolioGroup1, 
	.BlogPage #Portfolio1 .PortfolioGroup1.pageTitle, 
	.PortfolioPage #Portfolio1 .PortfolioItemContainerOuter.PortfolioGroup1, 
	.PortfolioPage #Portfolio1 .PortfolioGroup1.pageTitle {
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	.NewsArticle #Portfolio1 .PortfolioGroup1, 
	.NewsHolder #Portfolio1 .PortfolioGroup1, 
	.BlogPage #Portfolio1 .PortfolioGroup1, 
	.PortfolioPage #Portfolio1 .PortfolioGroup1 {
	    opacity: 1 !important;
	}
	/*** About us***/
	#AboutUs3 #aboutUsSLiderContainer {
	    opacity: 1 !important;
	    -webkit-transform: translateX(0px) !important;
	    -moz-transform: translateX(0px) !important;
	    -ms-transform: translateX(0px) !important;
	    transform: translateX(0px) !important;
	}
	#AboutUs3 .pageTitle {
	    opacity: 1 !important;
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	#CareersSection .AboutUsGoupCareers {
	    opacity: 1 !important;
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	#CareersSection .AboutUsGoupCareers {
	    opacity: 1 !important;
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	#CareersSection .AboutUsGoupCareers {
	    opacity: 1 !important;
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	#FundraisingSection .pageTitle {
	    opacity: 1 !important;
	    -webkit-transform: translateY(0px) !important;
	    -moz-transform: translateY(0px) !important;
	    -ms-transform: translateY(0px) !important;
	    transform: translateY(0px) !important;
	}
	#FundraisingSection .left {
	    opacity: 1;
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	}
	#FundraisingSection .right {
	    opacity: 1;
	    -webkit-transform: translateX(0px) scale(1) !important;
	    -moz-transform: translateX(0px) scale(1) !important;
	    -ms-transform: translateX(0px) scale(1) !important;
	    transform: translateX(0px) scale(1) !important;
	}

	.TextSection,
	.ImageSectionLeft,
	.ImageSectionRight{
		.ImageGroup2{
			opacity: 1 !important;
		}
		.ImageGroup1{
			opacity: 1 !important;
		}
		img.ImageGroup1{
			@include transform(translateX(0px) scale(1) !important);
		}
		.ImageGroup1.pageTitle{
			@include transform(translateX(0px) !important);
		}
		.ImageGroup2.pageTitle{
			@include transform(translateX(0px) !important);
		}
		.ImageGroupContent{
			@include transform(translateY(0px) !important);
		}
	}

	/*** Stop Lazy Loading ***/
}
@media all and (max-width: 660px)  {
    
    .bannerText .group1 {
	    	font-size: 1.5em;
	}	

}
@media all and (max-width: 550px)  {
	#innerSmall {
	    padding: 40px 20px !important;
	}

	#Home7 .innerSmall{
		padding-top: 20px !important;
		padding-bottom: 20px !important;
	}
	#Home4 .innerSmall {
	    padding-top: 60px !important;
	}
	#MobileNavigation .TopLevel{
		max-width: 90%;
	}
	#Home7 {
	    padding: 20px 0px 20px 0px !important;
	}
	
	#DragSlider {
    	position: absolute;
	    top: 20px;
	    left: 25px;
	    min-width: 0px !important;
	    width: 249px !important;
	    overflow: hidden;
	    height: 0px;
	    min-height: 189px !important;
	}
	.hover{
		bottom: 0% !important;
	}

	#Home7,
	#Home4{
		.pageTitle{
			padding-right: 0px !important;
		}
	}


	.SchoolBrandingPage {
		.pagePadding {
	    	padding-top: 145px !important;
		}
	}

	.ContactForm{
		.pagePadding {
	    	padding-top: 145px !important;
		}
	}

	.MarketingPage #Marketing5 .col-2 {
	    opacity: 1 !important;
	    width: 50%;
	    padding-bottom: 50%;
	    @include transition(all 0ms 0ms !important);
	}

	.WebsitesPage .ipadSliderContainerInner {
	    margin: 0 auto;
	    width: 300px;
	}
	.WebsitesPage .ipadSlider {
	    position: absolute;
	    top: 20px;
	    left: 24px;
	    width: 250px;
	    height: 189px;
	}

	.pagePadding {
	    padding-top: 145px !important;
	}
	.AboutUsPage .whiteBannerSection .innerCenter {
	    padding: 0px 33px;
	    font-size: 0.7em;
	    line-height: 1.3em;
	}
	.AboutUsPage .titleRight {
    	font-size: 1.2em !important;
	}
	.AboutBlock {
		display: block !important;
		.col-4, .col-8{
			width: 100%;
			clear: both;
			text-align: center;
		}
	}
	#FundraisingSection *{
		text-align: center;
	}
	
	.pageTitle {
	    font-size: 1.4em !important;
	}

	.image{
		width: 100% !important;
		padding-bottom: 100% !important;
	}
	#Home6 .centerText {
	    font-size: 1.1em !important;
	}

	#Home5 .LinkBox {
	    opacity: 1 !important;
	    width: 100%;
	    padding-bottom: 100%;
	    @include transition(all 0ms !important);
	}
	.ourTeamSection .pageTitle,
	#Home4 .pageTitle{
		text-align: center !important;
	}

	#Home3 .PortfolioTitle{
		text-align: center !important;
	}
	
	.ourTeamSection .pageTitle{
		padding-bottom: 20px !important;
	}
	#AboutButton,
	#ClientsButton,
	#PortfolioButton{
	    position: relative;
	    @include transform(translate(0%, 0%) !important);
	    margin: 0 auto;
	    display: block;
	    margin-top: 30px !important;
	    right: auto !important;
	    top: auto !important;
	    width: 120px !important;
	}	
	.ourTeamSection.text{
		font-size: 1.2em !important;
    	margin-top: 30px !important;
    	text-align: center !important;
	}
	#TestimonialsSlider{
		text-align: center;
	}
	.bx-controls{
		text-align: center;
	}
	.bx-default-pager {
	    margin-top: 20px;
	    display: inline-block;
	}
	.sliderSection.right {
	    font-size: 1.2em !important;
	}	

	.portfolioItemClear {
	    clear: both !important;
	    width: 100% !important;
	    height: auto !important;
	    min-height: 0px !important;
	    padding-left: 0px !important;
	    padding-right: 0px !important;
	    margin-bottom: 30px;
	}
	.PageControls .Previous a, .PageControls .Next a {
	    font-size: 0.78em !important;
	}
	#MobileNavigation .TopLevel {
   	 max-height: 300px !important;
	}
	.PortfolioPage .pagePadding {
	    padding-top: 120px !important;
	}
	
	.PortfolioGroup1.pageTitle{
		@include transform(translateY(0px) !important); 
	}
	
	#EstateAgents1{
		padding-top:150px !important;
		text-align: center;
	}
	#EstateAgents1{
	     padding-top: 175px !important;
	}
}
@media all and (max-width: 450px)  {

	#Home8 {
		.pageTitle{
			text-align: center;
		}
	}

	.twitterBoxBottom{
		text-align: center !important;
		.right,
		.left{
			width: 100%; 
			clear: both;
		}
		.right{
			padding-top: 10px;
			padding-bottom: 20px;
		}
	}

	.pageLink{
		width: 100%;
		clear: both;
		@include transition(all 0ms !important);
	}

	header #Logo {
	    width: 110px !important;
	}
	.clearSmall{
		clear: both;
		width: 100% !important;
	}

	.tacSmall{
		text-align: center !important;
	}
	
}

/*** Iphone 6 Landscape ***/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
	.bannerText{
		display: none !important;
	}
	.pagePadding {
	    padding-top: 80px !important;
	}
	.PortfolioItem .hover .part-1 {
    	font-size: 1em !important;
	}
	#SchoolBranding1{
		padding-top: 130px !important;
	}
	.hover{
		bottom: 0% !important;
	}
	.portfolioItemClear {
	    clear: both !important;
	    width: 100% !important;
	    height: auto !important;
	    min-height: 0px !important;
	    padding-left: 0px !important;
	    padding-right: 0px !important;
	    margin-bottom: 30px;
	}
}
/*** Iphone 5 Landscape ***/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) {
	.bannerText{
		display: none !important;
	}
	.pagePadding {
	    padding-top: 80px !important;
	}
	.PortfolioItem .hover .part-1 {
    	font-size: 1em !important;
	}
	#SchoolBranding1{
		padding-top: 130px !important;
	}
	.hover{
		bottom: 0% !important;
	}
	.portfolioItemClear {
	    clear: both !important;
	    width: 100% !important;
	    height: auto !important;
	    min-height: 0px !important;
	    padding-left: 0px !important;
	    padding-right: 0px !important;
	    margin-bottom: 30px;
	}
}

/*** Ipad Portrait ***/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
	.twitterSection{
		clear: both !important;
		width:  100% !important;
	}

	.twitterSection{
		padding: 0px !important;
	}

	.twitterMatch{
		min-height: 0px !important;
	}
	.AboutUsPage{
		.bx-default-pager {
			text-align: center;
		}
		#aboutUsSLider{
			.col-8,
			.col-4{
				clear: both;
				width: 100% !important;
				text-align: center;
			}
		}
	}
	#FundraisingSection{
		text-align: center;
	}
	.hover{
		bottom: 0% !important;
	}
}