/*** Mobile Navigation ***/
/* #Logo{
  display: none !important;
} */
#MenuIcon{
  

  position: absolute;
    z-index: 9999;
    display: none;
    float: left;
    right: 0px;
    border: 1px solid #565656;
    width: 48px;
    top: 0px;
    height: 46px;
    cursor: pointer;
    overflow: hidden;
    background-color: #000000;
    @include transition($t1);
    span{
      position: absolute;
      display: block;
      height: 2px;
      width: 21px;
      right: 13px;
      background-color: $themeColor;
      @include transform(rotate(0deg));
      @include transition($t1);
    }
    /*&:hover span{
      background-color: $textLight;
    }*/
    p{
      margin-bottom: 0px !important;
      font-size: 0.8em;
      color: $themeColor;
      text-transform: uppercase;
      font-weight: lighter;
      display: none;
      position: absolute;
      right: 10px;
      @include transform(translateY(-50%));
      bottom: 12px;
    }
    span:nth-child(1){
        top: 15px;
    }
    span:nth-child(2){
        top: 21px;
        width: 14px;
    }
    span:nth-child(3){
         top: 27px;
     width: 17px;
    }

    &:hover{
    span{
      background-color: #fff !important;
     }
      border: 1px solid $themeColor;
      background-color: $themeColor;

      span:nth-child(1){
            top: 15px;
            background-color: #fff !important;
            width: 17px;
        }
        span:nth-child(2){  
            top: 21px;
            background-color: #fff !important;
            width: 21px;
        }
        span:nth-child(3){
             top: 27px;
          width: 14px;
          background-color: #fff !important;
        }

    }
}
/*** Menu ***/
#MenuBg{
  position: absolute;
  width: 0%;
  height: 300%;
  left: 50%;
  top: 50%;
  background-color: $themeColor;
  @include transform(translate(-50%, -50%) rotate(-45deg));
  @include transition(all 1s ease-in-out);
  display: none !important;
}
#MobileNavigation{
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px;
  height: 0%;
  overflow: hidden;
  width: 100%;
  opacity: 0;
  .toggleNav{
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 0px;
    font-weight: bold;
    top: 18px;
    width: 30px;
    height: 30px;
    z-index: 9999; 
    color: #2b2b2b;
    font-size: 27px;
    border: none;
    outline: none;
  }
  .TopLevel{
    max-height: 75vh;
    padding: 10px;
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 50%;
    overflow-y: auto;
    @include transform($center);
    max-width: 500px;
    width: 100%;
    text-align: left;

    &:before{
      content: '';
      position: absolute;
      display: block;
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      opacity: 0.6;
      height: 50px;
      width: 50px;
      top: -20px;
      left: -20px;
    }
  }
   .Level1{
          @include transform(translateY(-20px));
          opacity: 0;
       } 
  ul{
    ul{
      display: none;
      background: rgba(255,255,255,0.05);
    }
    li{
    
      position: relative;
      a.current{

         color: #2b2b2b !important;
      }
      a{
       /* color: #dedede;
         color: #949494;*/
         color: #a7a7a7;
        text-align: center;
        font-size: 2em;
        width: 100%;
        text-transform: uppercase;
        font-family: $font-stack;
        width: 100%;
        display: block;
        font-weight: bold;
        padding: 10px 34px 10px 34px;
        letter-spacing: -1px;
        text-decoration: none !important;
        @include transition($t1);

         &:after {
          content: '';
          position: absolute;
          display: block;
          border-right: 1px solid #bdbbbb;
          border-bottom: 1px solid #bdbbbb;
          height: 20px;
          bottom: 0px;
          width: 20px;
          right: 0px;
          opacity: 0;
        }
        &:before {
          content: '';
          position: absolute;
          display: block;
          border-left: 1px solid #bdbbbb;
          border-top: 1px solid #bdbbbb;
          height: 20px;
          top: 0px;
          width: 20px;
          left: 0px;
          opacity: 0;
        }
        
        &:hover{
          &:after,
          &:before {
            opacity: 1 !important;
          }

         color: $themeColor !important;
          background: rgba(255,255,255,0.1);/* 
          @include box-shadow(inset 0px 0px 0px 1px #eee); */
        }
      }
    }
  }
}

#particles-js{
  position: absolute;
  z-index: 0;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
}

.closeMenu{
  position: fixed;
  top: 30px;
      background-color: #fff;
  left: 50px;
  z-index: 9999;
  height: 40px;
  width: 40px;
  cursor: pointer;
  opacity: 0;
  @include transform(scale(0.5));

  .closeHover{
    color: $themeColor;
    text-transform: uppercase;
    font-size: 0.4em;
    letter-spacing: 3px;
    width: 80px;
    position: absolute;
    top: 16px;
    opacity: 0;
    @include transform(translateX(30px));
    left: 59px;
    text-align: right;
    padding-bottom: 6px;/* 
    border-bottom: 1px solid #eee; */

    .closeletter{
      color: $themeColor;
      text-transform: uppercase;
      letter-spacing: 3px;
      opacity: 0;
    }
    &:before {
        content: "";
        position: absolute;
        display: block;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        z-index: 3;
        bottom: -3px;
        background-color: #ddd;
    }
    .bottomLine {
        content: "";
        position: absolute;
        display: block;
        height: 1px;
        width: 0%;
        left: 0px;
        bottom: -1px;
        @include transition(all 0.5s);
        background-color: #eee;
    }
  }

  span.line{
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $themeColor;
    height: 2px;
    width: 15px;
    @include transition($t1);
    z-index: 3;
  }
   span.line:first-of-type{
        @include transform($center rotate(45deg));
   }

   span.line:last-of-type{
        @include transform($center rotate(-45deg));
   }

   &:hover{
    span.line:first-of-type{
         @include transform($center rotate(-45deg));
    }

    span.line:last-of-type{
          @include transform($center rotate(45deg));
    }
      
   }

  .closeHover.active{
      opacity: 1 !important;
      @include transform(translateX(0px) !important);
       .closeletter{
        opacity: 1 !important;
      }
       .bottomLine {
        width: 100% !important;
      }
  }

   &:before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    @include border-radius(50%);
    top: 50%;
    border: 6px solid #eee;
    box-shadow:0px 0px 0px 2px rgb(255,255,255), 0px 0px 0px 3px rgb(200,200,200);
    -o-box-shadow:0px 0px 0px 2px rgb(255,255,255), 0px 0px 0px 3px rgb(200,200,200);
    -ms-box-shadow:0px 0px 0px 2px rgb(255,255,255), 0px 0px 0px 3px rgb(200,200,200);
    -moz-box-shadow:0px 0px 0px 2px rgb(255,255,255), 0px 0px 0px 3px rgb(200,200,200);
    -webkit-box-shadow:0px 0px 0px 2px rgb(255,255,255), 0px 0px 0px 3px rgb(200,200,200);
    @include transform($center rotate(45deg));
   }
    &:after{
    content: "";
    position: absolute;
    display: block;
    width: 120%;
    background-color:#fff;
    height: 13px;
    left: 50%;
    top: 50%;
    z-index: 1;

    animation: rotateMe 5s linear 0s infinite alternate;
    @include transform($center rotate(45deg));
   }
}

@keyframes rotateMe {
    0%   {@include transform($center rotate(0deg));}
    100% {@include transform($center rotate(360deg));}
}

.activeLinksMenu{
   .closeMenu{
    opacity: 1 !important;
    @include transform(scale(1) !important);
  }
}
.activeMobileMenu{
  overflow: hidden;

  .closeMenu{
    opacity: 1 !important;
    @include transform(scale(1) !important);
  }

  .Level1{
    @include transform(translateY(0px) !important);
      opacity: 1 !important;
  }
  #MobileNavigation{
    display: block;
    right: 0px;
    opacity: 1  !important;
    margin-top: 0px !important ;
    height: 100% !important;
  }
  #MenuBg{
    width: 200%;
    height: 300%;
  }
/*    #MenuIcon {
      span:nth-child(1){
        top: 20px;
        @include transform(translateX(-50%)  rotate(45deg));
      }
      span:nth-child(3){
        @include transform(translateX(-50%)  rotate(-45deg));
        top: 20px;
      }
      span:nth-child(2){
        margin-left: 100px !important;
        opacity: 0 !important;
        width: 50%;
      }
      span:nth-child(4){
        opacity: 0 !important;
      }
  } */
}