

*{
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
}

.section{
	width: 100%;
	float: left;
	clear: both;
}
 
b, strong{
	font-weight: bold !important;
}

@font-face {
    font-family: 'GeorgiaItalic';
    src: url('../fonts/GeorgiaItalic.eot');
    src: url('../fonts/GeorgiaItalic.eot') format('embedded-opentype'),
         url('../fonts/GeorgiaItalic.woff2') format('woff2'),
         url('../fonts/GeorgiaItalic.woff') format('woff'),
         url('../fonts/GeorgiaItalic.ttf') format('truetype'),
         url('../fonts/GeorgiaItalic.svg#GeorgiaItalic') format('svg');
}

@font-face {
    font-family: 'Georgia';
    src: url('../fonts/Georgia.eot');
    src: url('../fonts/Georgia.eot') format('embedded-opentype'),
         url('../fonts/Georgia.woff2') format('woff2'),
         url('../fonts/Georgia.woff') format('woff'),
         url('../fonts/Georgia.ttf') format('truetype'),
         url('../fonts/Georgia.svg#Georgia') format('svg');
}

@font-face {
    font-family: 'GeorgiaBoldItalic';
    src: url('../fonts/GeorgiaBoldItalic.eot');
    src: url('../webfonts/GeorgiaBoldItalic.eot') format('embedded-opentype'),
         url('../fonts/GeorgiaBoldItalic.woff2') format('woff2'),
         url('../fonts/GeorgiaBoldItalic.woff') format('woff'),
         url('../fonts/GeorgiaBoldItalic.ttf') format('truetype'),
         url('../fonts/GeorgiaBoldItalic.svg#GeorgiaBoldItalic') format('svg');
}




/*** Draggable start ***/
#DragSlider{
	position: absolute;
    top: 57px;
    left: 68px;
    min-width: 0px !important;
    width: 670px !important;
    overflow: hidden;
    height: 506px;
}
#dragPrev,
#dragPrev{
	width: 40px;
	height: 40px;
	margin: 10px;
	border: none !important;
	background-color: #000;
	@include border-radius(50%);
	padding: 0px;
    line-height: 0.9em;
    cursor: position;
	i{
		color: #fff !important;
	}
}
#dragContainer{
	max-width: 100%;
	overflow: hidden;
}
.draggableSlide{
	width: 100%;
	float: left;
	height: 100px;
	background-size: cover;
	background-position: center;
	overflow: hidden;
	display: block;
}
.currentSlide{
	background-color: #0c0;
}
/*** Draggable start ***/

.SearchContainer{
	display: none;
	position: absolute;
    bottom: 0px;
    @include transform(translateY(100%))
    background-color: #e0e0e0;
    padding: 7px;
    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #d32237;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    i{
	    	color: #fff !important;
	    }
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
    }
}

.flexslider{
	    width: 100%;
    height: 100%;
}


.flexslider .slides li,
.flexslider .slides {
    margin: 0px;
    width: 100%;
    height: 100%;
}
.slideBG,
.BannerContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}

#gmap_canvas{
	min-height: 400px;
	clear: both;
	width: 100%;
	float: left;
}

#MemberLoginForm_LoginForm_action_dologin{
	margin-bottom: 40px;
}

.ContentForm{

	form{
	    padding-bottom: 60px;
	}
	label{
		color: $themeColor !important;
		font-weight: lighter !important;
		text-transform: uppercase !important;
		font-size: 1em;
		@include transition($t1);
		position: absolute;
		@include transform(translateY(0px));
	}

	/*.ActiveLabel{
		@include transform(translateY(0px));
	}*/

	textarea,
	input[type=email],
	input[type=password],
	input[type=text]{
		padding: 5px 10px;
		border: 1px solid transparent;
		border-bottom: 1px solid #2b2b2b;
		
	margin-top: 40px;
		margin-bottom: 15px;
		width: 100%;
		max-width: 400px;
		@include transition($t1);
		background-color: transparent;
	}
	input[type=submit]{
		/* border: none;
		border-top: 1px solid #8da444;
		border-bottom: 1px solid #8da444;
		background-color: transparent !important;
		padding: 15px 25px;
		margin-top: 20px;
		font-weight: lighter;
		font-size: 1.2em; */
		@include border-radius(0px !important);
	    border: none;
	    border: 2px solid $themeColor;
	    padding: 10px 35px;
	    margin-top: 20px;
	    letter-spacing: 0px;
		font-size: 0.8em;
		text-transform: uppercase;
		left: 100px;
		position: absolute;
		bottom: 13px;
		cursor: pointer;
	    @include transform(translate(-50%, 50%));
	    @include transition($t1);

	background-color: #f6f5f6;
	    &:hover{
	    	background-color:  $themeColor;
	    	color: #fff;
	    }
	}
	textarea.ActiveInput,
	input[type=email].ActiveInput,
	input[type=password].ActiveInput,
	input[type=text].ActiveInput{
		background-color: #eee;
		border: 1px solid $themeColor !important;
	}
}


.CategoryBreadcrumbs{
	font-size: 0.7em;
	span{
		font-weight: bold !important;
	}
	margin-bottom: 20px !important;
}

.bgWhite{
	background-color: #fff;
}

.fixedBG{
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
}

.mobMenu{
	position: absolute;
	top: 58px;
	right: 0px;
	@include transition($t1);
}
.hideLinksDesktop{
	display: none;
}
#LinksIcon{
	display: none;
    text-transform: uppercase;
    font-size: 0.7em;
    letter-spacing: 2px;
    position: absolute;
    z-index: 9999;
    padding-top: 15px;
    float: left;
    right: 47px;
    border: 1px solid #565656;
    width: 69px;
    color: #d32237;
    text-align: center;
    top: 0px;
    height: 46px;
    cursor: pointer;
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    -ms-transition: all 0.35s;
    transition: all 0.35s;
    overflow: hidden;
    background-color: #000;

    &:hover{
    	background-color: $themeColor !important;
    	color: #fff !important;
		border: 1px solid $themeColor !important;
    }
}

#LinksMenu{
	 background-color: #fff;
	  position: fixed;
	  z-index: 9999;
	  top: 0px;
	  left: 0px;
	  height: 0%;
	  overflow: hidden;
	  width: 100%;
	  opacity: 0;

	  .InnerLinkMenu{
	  	padding: 10px;
	    position: absolute;
	    display: inline-block;
	    left: 50%;
	    top: 50%;
	    overflow-y: auto;
	    @include transform($center);
	    max-width: 300px;
	    width: 100%;
	    text-align: left;

	    .quickLinks a{
	    	/*color: #dedede;
	    	color: #949494;*/
	    	color: #a7a7a7;
	        text-align: center;
	        font-size: 2em;
	        width: 100%;
	        text-transform: uppercase;
	        font-family: $font-stack;
	        width: 100%;
	        display: block;
	        font-weight: bold;
	        opacity: 0;

          	@include transform(translateY(-20px));
	        padding: 10px 34px 10px 34px;
	        letter-spacing: -1px;
	        text-decoration: none !important;
	        @include transition($t1);
	        &:hover{
				&:after,
	          &:before {
	            opacity: 1 !important;
	          }
	         color: $themeColor !important;
	          background: rgba(255,255,255,0.1);/* 
	          @include box-shadow(inset 0px 0px 0px 1px #eee); */
	        }
	    }

	    .social{
	     	opacity: 0;
          	@include transform(translateY(-20px));
			text-align: center;
			font-size: 1.6em;
			margin-top: 20px;

			a i{
	    		color: #dedede;
	    		 &:hover{

		         color: #2b2b2b !important;
		          background: rgba(255,255,255,0.1);/* 
		          @include box-shadow(inset 0px 0px 0px 1px #eee); */
		        }
			}
	    }
	  }
}
.activeLinksMenu{
  	overflow: hidden !important;
	#LinksMenu{
	    display: block;
	    right: 0px;
	    opacity: 1  !important;
	    margin-top: 0px !important ;
	    height: 100% !important;

	    .social{
	   		opacity: 1 !important;
          	@include transform(translateY(0px) !important);
	    }
	    .InnerLinkMenu .quickLinks a{
	    	opacity: 1 !important;
          	@include transform(translateY(0px) !important);
	    }
	  }
 }

.flex{
	display: flex;
}

.showParallax{
	opacity: 0;
	@include transition(all 1s);
}
.parallaxReady{
	.showParallax{
		opacity: 1 !important;
	}
}


/*** Linkboxes ***/
.LinkBoxes{
	.LinkBox{
		opacity: 0;
		float: left;
		overflow: hidden;
		padding-bottom: 20%;
		position: relative;
		.LinkBoxBg{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			@include transition(all 1s);
			background-size: cover;
			background-position: center;
			background-image: url(../images/Reddoorimages/bg.jpg);
			@include transform(scale(1));
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			z-index: 4;
		}
		.LinkBoxHover{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 101%;
			height: 101%;
			background-color: rgba(0,0,0,0.82);
			@include transform($center scale(1));
			@include transition(all 1s);
			opacity: 1;
			.LinkBoxCenter{
				position: absolute;
				width: 80%;
				margin: 0 auto;
				top: 50%;
				left: 50%;
				@include transform($center);
				.part-1{
					width: 100%;
					clear: both;
					float: left;
					padding: 20px 0px;
					color: #fff;
					font-size: 1.5em;
					font-weight: lighter;
					border-bottom: 1px solid $themeColor;
				}
				.part-2{
					width: 100%;
					clear: both;
					float: left;
					padding: 20px 0px;
					color: #fff;
					font-weight: lighter;
					text-transform: uppercase;
				}
			}
		}
		/* .LinkBoxHover-1{background-color: rgba(149, 170, 80, 0.9);}
		.LinkBoxHover-2{background-color: rgba(113, 197, 173, 0.9);}
		.LinkBoxHover-3{background-color: rgba(176, 73, 134, 0.9);}
		.LinkBoxHover-4{background-color: rgba(121, 68, 107, 0.9);}
		.LinkBoxHover-0{background-color: rgba(149, 170, 80, 0.9);} */
		&:hover{
			.LinkBoxBg{
				@include transform( scale(1.2) !important);
			}
			.LinkBoxHover{
				opacity: 0;
				@include transform($center scale(0.5));
			}
		}
	}
}


#Home5.activeIn{
	.LinkBox{
		opacity: 1 !important;
	}
}

#Home4{
	.innerSmall{
		padding-top: 130px;
		padding-bottom: 30px;
	}
}
#Home6{
	min-height: 700px;
	min-height: 60vh;
	/*opacity: 0;*/
	/*@include transition(1s);*/
	.bg{
		height: 120% !important;
	}
	.centerText{
		opacity: 0;
		@include transition(1.5s 1.2s);
	}
}

#Home6.activeIn{
	.centerText{
		opacity: 1 !important;
	}
}

#Home6.activeIn{
	/*opacity: 1 !important;*/
}

#Home7{
	padding: 100px 0px  170px 0px;
}

.ContactGroup1{
	opacity: 0;
}

.PageLoaded .ContactGroup1{
	opacity: 1 !important;
}

/*** Linkboxes ***/	
#PortfolioButton{
    position: absolute;
    left: 50%;
    @include transform(translateX(-50%));
    bottom: 10%;
    width: 66%;
}
#ClientsButton{
		position: absolute;
	    right: 40px;
	    top: 42%;
	    @include transform(translate(50%, -50%));
	 span{
		color: #2b2b2b !important;
	}
}

.contentButton{
	 span{
		color: #2b2b2b !important;
	}
}

#TestimonialsSliderContainer{
	margin-top: 40px;
	@include transition(all 1.5s 0.5s);
	opacity: 0;
	@include transform(translateY(10%));
}
.topFade{
	@include transition(all 1.5s 0.5s);
	opacity: 0;
}

#Home4.activeIn{
	#TestimonialsSliderContainer{
		opacity: 1 !important;
		@include transform(translateY(0%) !important);
	}
	.topFade{
		opacity: 1 !important;
	}
}

.sliderSection.left{
	text-transform: uppercase;
	font-size: 1.4em;
	color: #4d484b;
	font-weight: lighter;
}

.sliderSection.right{
	color: #4d484b;
	font-weight: lighter;
	font-size: 1.4em;
	line-height: 1.4em;
}

.sliderSection{
	padding: 20px 0px;
}

.bx-default-pager{
	margin-top: 20px;
}

.bx-pager-item {
    width: 20px;
    height: 20px;
    display: inline-block;
    @include border-radius(50%);
    margin-right: 8px;
    cursor: pointer;
}
.bx-pager-link{
 	width: 20px;
    height: 20px;
    display: inline-block;
    @include border-radius(50%);
    background-color: #cfcdcb;
    position: relative;
    color: transparent !important;
    @include transition($t1);
}
.bx-pager-link.active{
    background-color: $themeColor !important;
}

.rightBannerLinks{
	position: absolute;
	top: 50%;
	right: 0px;
	@include transform(translateY(-50%));
	a{
		display: block;
		position: relative;
	    width: 200px;
	    font-size: 1.2em;
	    color: #fff;
	    font-weight: 200;
	    border-bottom: 1px solid rgba(255,255,255,0.4);
	    padding: 10px 50px 10px 0px;
	    .circleBgLinks{
	    	background-color: #d32237;
		    padding: 10px;
		    @include border-radius(50%);
	    	position: absolute;
	    	width: 35px;
	    	height: 35px;
	    	right: 15px;
	    	top: 50%;
	    	@include transform(translateY(-50%));
	    	img{
				top: 50%;
				position: absolute;
				max-width: 20px;
				left: 50%;
				@include transform($center); 
	    	}
	    }
	   /*  img{
	    	position: absolute;
	    	width: 35px;
	    	right: 15px;
	    	top: 50%;
	    	@include transform(translateY(-50%));
	    } */
	}
}



body{
	overflow-y: scroll;
	overflow-x: hidden;
}

/*** Scroll Bar ***/
::-webkit-scrollbar {
    width: 8px;
    background-color: #2b2b2b;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 0px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #5b5b5b;
    -webkit-box-shadow:  0px 0px 2px 0px rgba(0,0,0,0.5); 
}

.TopLevel::-webkit-scrollbar {
    width: 4px;
    background-color: #eee;
}
 
.TopLevel::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 0px;
}
 
.TopLevel::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #5b5b5b;
    -webkit-box-shadow:  0px 0px 2px 0px rgba(0,0,0,0.0); 
}

img{
	max-width: 100%;
	height: auto !important;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

.slink{
	cursor: pointer;
}


/*** Smooth Scrolling ***/
.smooth {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: $mainColor;
    @include perspective(1000);
    @include transform-origin(0 0);
	@include box-sizing(border-box);
    @include backface-visibility(hidden);
    @include transform(translate3d(0px, 0px, 0px));
    @include transition(all .5s cubic-bezier(0.2, .84, .5, 1));
}

.wrapper{
	position: relative;
	float: left;
	width: 100%;
	background-color: $mainColor;
}

.SmoothScroll{
	 .parallax1 {
		@include perspective(1000);
	    @include transform-origin(0 0);
		@include box-sizing(border-box);
	    @include backface-visibility(hidden);
	    @include transform(translate3d(0px, 0px, 0px));
	    @include transition(all .5s cubic-bezier(0.2, .84, .5, 1));
	}
}

.StandardScroll{
	.smooth{
		 position: relative;
	}
}

/*** Loading Bar ***/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 200px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #29d;
  overflow: hidden;
}

.pace .pace-progress {
	@include box-sizing(border-box);
	@include transform(translate3d(0, 0, 0));
	max-width: 200px;
	position: fixed;
	z-index: 2000;
	display: block;
	position: absolute;
	top: 0;
	right: 100%;
	height: 100%;
	width: 100%;
	background: #29d;
}

.pace.pace-inactive {
  display: none;
}

.pageTitle{
    font-weight: lighter;
    text-transform: uppercase;
    color: #000;
    line-height: 1em;
    font-size: 2.55em;
}
.innerSmall{
	padding: 100px 20px;
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;
	clear: both;
}


.innerCenter{
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;
	clear: both;
}

.ourTeamSection.text{
	color: #4d484b;
    font-weight: lighter;
  /*  font-size: 1.7em;*/
    font-size: 1.4em;
    line-height: 1.35em;
}
#AboutButton{
	position: absolute;
	bottom: 0px; 
    left: 40px;
    @include transform(translateX(-50%) scale(1) !important);
}

.ourTeamSection:last-of-type{
	opacity: 0;
	@include transition(all 2s 0.5ms);
	@include transform(translateX(20%));
}
.ourTeamSection:first-of-type{
	opacity: 0;
	@include transition(all 1.5s 0.5ms);
	@include transform(translateX(-20%));
}

#Home7.activeIn{
	.ourTeamSection:last-of-type,
	.ourTeamSection:first-of-type{
		opacity: 1 !important;
		@include transform(translateX(0%) !important);
	} 
}


#Home8{
	@include transition( all 1s);
	background-color: #fff;
	overflow: hidden;
	padding-bottom: 100px;
	min-height: 400px !important;

	.pageTitle{
		color: #fff !important;
		opacity: 0;
		margin-bottom: 40px;
		@include transition(all 1.5s 1s);
	}
}


.twitterBoxTop{
	background-color: #fff;
	padding: 25px;
	padding-bottom: 40px;
	.title{
		padding-bottom: 20px;
		span{
			color: $themeColor !important;
			font-size: 1.3em;
			font-weight: lighter;
		}
		img{
			width: 40px;
			margin-right: 30px;
			vertical-align: middle;
		}
	}
	.text{
		color: #4d484b;
	    font-size: 1.2em;
	    line-height: 1.5em;
	    font-weight: lighter;
	}
}
.twitterBoxBottom{
	padding: 10px 0px;
	
	.right{
		float: right;
		svg{
			display: inline-block;
			verticale-align: middle;
		}
		svg path{
				@include transition($t1);
				fill: #ffffff !important;
			}
		a:hover{
			svg path{
				fill: #000 !important;
			}
		}
	}
	.left{
		color: #eee;
	    font-weight: lighter;
		float: left;
	}
}

.tweetLink {
	display: inline-block;
	margin-left: 10px;
	vertical-align: middle;
	img{
		vertical-align: middle;
		display: inline-block;
		max-width: 17px !important;
	}
}

.twitterSection{
	opacity: 0;
	@include transform(translateY(10%));
}
.twitterSection:first-of-type{
	@include transition(all 1.5s 1.5s);

}
.twitterSection:last-of-type{
	@include transition(all 1.5s 2s);

}

#Home8.activeIn{
	background-color: #d32237 !important;
	.pageTitle{
		opacity: 1 !important;
	}
	.twitterSection{
		opacity: 1 !important;
		@include transform(translateY(0%) !important);
	}
}



#Home2{
	
	.pageTitle{
		padding: 0px 20px;
		margin-bottom: 50px;
		opacity: 0;
		padding: 80px 20px 0px 20px;
	}

	.contentText {
        color: #4d484b ;
        font-weight: lighter;
        font-size: 1.2em;
        line-height: 1.35em;
        margin-bottom: 60px;
    }

	.innerSmall{
		padding-top: 0px !important;
	}
	.HomeService{
		@include transform(scale(0.7));
		opacity: 0;
		padding: 0px 20px;
		margin-bottom: 50px;
		.Icon{
			position: relative;
			display: block;
			width: 70px;
			height: 70px;
			margin-bottom: 15px;
			@include border-radius(50%);
			background-color: $themeColor !important;
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform($center);
				max-width: 49px;
			}
		}
		.Title h3{
			color: #313146;
		    font-size: 2.2em;
		    font-weight: lighter !important;
		}
		.Title h2{ 
			color: #313146;
		    font-size: 2.2em;
		    font-weight: lighter !important;
		}
		.Text{
			color: #4d484b;
		    font-weight: lighter;
		    font-size: 1.2em;
		    line-height: 1.35em;
		}
		.ViewMore{
			color: #313146;
			display: inline-block;
			margin-top: 10px;
			padding: 12px 0px;
			position: relative;
			font-weight: lighter;
			&:after{
				content:'';
				display: block;
				background: $themeColor;
				width: 100%;
				bottom: 0px;
				left: 0px;
				height: 2px;
				position: absolute;
				@include transition(all 0.1s);
			}
			&:hover:after{
				height: 5px;
			}
		}
	}

}

.oh{
	overflow: hidden;
}
#Home2.activeIn{
	.pageTitle{
		opacity: 1 !important;
	}
	.HomeService{
		@include transform(scale(1));
		opacity: 1 !important;
	}
}

#imgScroll,
#Home3{
	/*background-color: #2b2b2b;*/
	color: #fff;
	/*opacity: 0;*/
	@include transition(all 1s);

	.PortfolioTitle{
		background-color: $themeColor;
		padding: 2.15% 3% 3% 3%;
		@include transform( translateX(-50%));
		.SliderTitle{
			text-transform: uppercase;
			font-size: 2.3vw;
			font-weight: lighter;
		}
		*{color: #fff;}
	}
	.group3{
		opacity: 0 !important;
	}
}
#imgScroll.activeIn,
#Home3.activeIn{
	/*opacity: 1 !important;*/
	.group3{
		opacity: 1 !important;
	}

	.shadow{
		height: 50% !important;
		width: 120% !important;
		opacity: 0.2 !important;
	}

	.group3.image{
		@include transform(rotateY(0deg) translateX(0%) !important);
	}

	.group3.PortfolioTitle{
		@include transform( translateX(0%) !important);
	}

}

#imgScroll{
	    perspective: 1000px;
}

.image{
    overflow: hidden;
    width: 25%;
    padding-bottom: 25%;
    float: left;

    @include transform(rotateY(50deg) translateX(90%));
    backface-visibility: hidden;

	float: left;
	background-size: cover;
	background-position: center;
	position: relative;
	background-image: url(../images/Reddoorimages/bg.jpg);

	

	a{
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 3;
		width: 100%;
		height: 100%;
	}
	.hover{
		background-color: #2b2b2b;
		background-color: rgba(0, 0, 0, 0.82);
		position: absolute;
		bottom: -50%;
		width: 100%;
		height: 50%;
		left: 0px;
		@include transition($t1);
		.center{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 80%;
			@include transform($center);
		}
		.part-1{
			font-size: 1.5em;
			font-weight: lighter;
			color: #fff;
			text-transform: uppercase;
			border-bottom: 1px solid $themeColor;
			padding-bottom: 10px;
		}
		.part-2{
			font-weight: lighter;
			color: #fff;
			padding-top: 10px;
		}
	}
	&:hover .hover{
		bottom: 0% !important;
	}

}

.isotope {
  -webkit-transition-property: height;
     -moz-transition-property: height;
      -ms-transition-property: height;
       -o-transition-property: height;
          transition-property: height;
}

/*** Portfolio ***/
.PortfolioItem{
    overflow: hidden;
    width: 25%;
    float: left;

    backface-visibility: hidden;

	float: left;
	.bg{
		position:absolute;
		@include transition(all 1s);
		@include transform( scale(1));
		top: -1%;
		left: -1%;
		width: 102%;
		height: 102%;
		background-size: cover;
		background-position: center;
		position: relative;
		background-image: url(../images/Reddoorimages/bg.jpg);
	}
	&:hover{
		.bg{
			@include transform( scale(1.2) !important);
		}
	}

	

	a{
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 3;
		width: 100%;
		height: 100%;
	}
	.hover{
		background-color: #2b2b2b;
		background-color: rgba(0, 0, 0, 0.82);
		position: absolute;
		bottom: -50%;
		width: 102%;
		height: 50%;
		left: -1%;
		@include transition($t1);
		.center{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 80%;
			@include transform($center);
		}
		.part-1{
			font-size: 1.5em;
			font-weight: lighter;
			color: #fff;
			text-transform: uppercase;
			border-bottom: 1px solid $themeColor !important;
			padding-bottom: 10px;
		}
		.part-2{
			font-weight: lighter;
			color: #fff;
			padding-top: 10px;
		}
	}
	&:hover .hover{
		bottom: 0% !important;
	}
}
/*** Portfolio ***/

.image-1 .bg{background-image: url(../images/lb-1.jpg) !important;}
.image-2 .bg{background-image: url(../images/lb-2.jpg) !important;}
.image-3 .bg{background-image: url(../images/lb-3.jpg) !important;}
.image-0 .bg{background-image: url(../images/lb-4.jpg) !important;}


.NewsSubTitle{
	margin-top: 20px;
	*{
		line-height: 1em;
		font-size: 1em;
	}
}

.bbContent{
	border-bottom: 10px solid $themeColor;
	padding-bottom: 30px;
	margin-bottom: 30px;
}
.NewsArticle,
.NewsHolder,
.BlogPage,
.PortfolioPage{
	.portfoliosideLinks{
	    position: absolute;
	    bottom: 40px;
	    left: 0px;
	    width: 100%;
	    opacity: 0;
	    @include transition(opacity 1s 1s);
	}
	.portfolioLeft .pageLink {
	    opacity: 0;
	    display: block;
	    padding: 10px 20px 0px 20px;
	    float: left;
	    opacity: 1 !important;
	    text-align: center;
	    width: 100% !important;
	    margin-top: 30px;
	    position: relative;
	    border-right: none;
	}
	.pageLink img {
    	max-width: 90px;
    	width: 100%;
    	max-height: 63px;
	}
	.pageTitle{
		font-size: 2em !important;
	}
	.pagePadding {
	    padding-top: 133px !important;
	}
	.section1{
		padding: 35px 20px 35px 20px !important;
	}
	 .PortfolioGroup1{
		opacity: 0;
	} 

	.PortfolioItemContainerOuter.PortfolioGroup1,
	.PortfolioGroup1.pageTitle{
		@include transform(translateY(50px));
	}
	.filters.PortfolioGroup1{
		@include transform(translateX(50px));
	}
	.activeIn#Portfolio1{
		.PortfolioGroup1{
			opacity: 1 !important;
		}
		.PortfolioItemContainerOuter.PortfolioGroup1,
		.PortfolioGroup1.pageTitle{
			@include transform(translateY(0px) !important);
		}
		.filters.PortfolioGroup1{
			@include transform(translateX(0px) !important);
		}
	} 

	.portfolioLeft{
		padding: 80px 2.5% 40px 2.5%;
		.pageTitle{
			color: #fff !important;
		}
	}
}
	.pageLink img {
	max-height: 63px !important;
	}
.PortfolioPage{
	.pagePadding {
	    /* padding-top: 200px !important; */
	}
}
.NewsArticle,
.NewsHolder,
.BlogPage{
	.PortfolioItem .hover {
		bottom: 0% !important;
	    height: 35% !important;
	}
	.PortfolioItem .hover .part-1 {
	    border-bottom: 1px solid $themeColor !important;
	}
	.PortfolioItem .hover .part-2 {
		text-transform: uppercase;
	}

	.portfoliosideLinks {
	    bottom: 90px;
	}
	.PortfolioItemContainerOuter{
		opacity: 1;
		padding-top: 80px;
		padding-bottom: 80px;
	    padding-top: 80px;
	    padding-bottom: 80px;
	    /* max-width: 1200px !important; */
	    min-width: 0px;
	}
/*	.News .hover{background-color: #92a64b;}
	.Projects .hover{background-color: #774076;}
	.Technology .hover{background-color: #ab4383;}
	.Marketing .hover{background-color: #70c1ab;}
	.EducationIndustry .hover{background-color: #6f6070;}
	.Design .hover{background-color: #838eb4;}*/
}
.NewsHolder{

	.PortfolioItem .hover .part-1 {
		font-size: 1.2em;
	} 
	.PortfolioItem .bg{background-image: url(../images/blog-multi-categories.jpg);background-size: contain;
	background-position: top;background-repeat: no-repeat;}
	.News .bg{background-image: url(../images/blog-categories-news.jpg);background-size: contain;
    background-position: top;background-repeat: no-repeat;}
	.Projects .bg{background-image: url(../images/blog-categories-projects.jpg);background-size: contain;
    background-position: top;background-repeat: no-repeat;}
	.Technology .bg{background-image: url(../images/blog-categories-technology.jpg);background-size: contain;
    background-position: top;background-repeat: no-repeat;}
	.Marketing .bg{background-image: url(../images/blog-categories-marketing.jpg);background-size: contain;
    background-position: top;background-repeat: no-repeat;}
	.EducationIndustry .bg{background-image: url(../images/blog-categories-education-industry.jpg);background-size: contain;
    background-position: top;background-repeat: no-repeat;}
	.Design .bg{background-image: url(../images/blog-categories-design.jpg);background-size: contain;
    background-position: top;background-repeat: no-repeat;}
}


.isotope{
	opacity: 0 !important;
}
.PortfolioActive{
	.isotope{
		opacity: 1 !important;
	}	
}

.articleInner{
	padding: 100px 10%;
}

.imgContainer{
	border-bottom: 10px solid $themeColor;
	padding-bottom: 30px;
	margin-bottom: 30px;
}

.activeSideLinks{
	.portfoliosideLinks{
		opacity: 1 !important;
	}
}

.ImageContainerInner{
	padding-bottom: 40%;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.PortfolioItemPage{
	.ImageContainerInner{
		padding-bottom: 50%;
		background-size: cover;
	}
}

.subFilter button.active:after {
    content: 'x';
    position: absolute;
    top: 50%;
    right: 0px;
    @include transform(translateY(-50%));
}

.PageControls{
	position: absolute;
	bottom: 0px;
	left: 0px;
	opacity: 0;
	@include transition(all 1s 500ms);
	img{
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		width: 16px;
	}
	.Previous,
	.Next{
		a{
			display: inline-block;
			position: relative;
			padding: 10px;
			@include transition($t1);
			&:hover{
				color: $themeColor !important;
			}
		}
	}

	.Previous{
		a{
			float: left;
			padding-left: 40px !important;
			font-weight: lighter;
			img{
				left: 10px;
			}
		}
	}
	.Next{
		a{
			float: right;
			text-align: right;
			padding-right: 40px !important;
			font-weight: lighter;
			img{
				right: 10px;
			}
		}
	}
}

.portfolioInnerGroup1{
	opacity: 0;
}

.PageLoaded{
	.portfolioInnerGroup1,
	.PageControls{
		opacity: 1 !important;
	}
}

.AdditionalImage{
	cursor: pointer;
	padding-bottom: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 1;
	@include transition($t1);
	&:hover{ 
		opacity: 0.8 !important;
	}
	&:after{
		content:'';
		position: absolute;
		display: block;
		top: 0px;
		right: 0px;
		width: 10px;
		height: 100%;
		background-color: #fff;
	}
}

.portfolioSliderNext,
.portfolioSliderPrev{
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}

	@include border-radius(50%);
    width: 30px;
    height: 30px;
    font-size: 1.3em;
    padding: 1px 0px 0px 0px;
 

    opacity: 0;
    @include transition($t1);
	z-index: 4;
	position: absolute;
	top: 50%;
	cursor: pointer;
	@include transform(translateY(-110%));
	i{
		color: #fff !important;
	}
	background-color: #2b2b2b;
	text-align: center;
}
.portfolioInnerGroup1:hover{
	.portfolioSliderNext,
	.portfolioSliderPrev{
		opacity: 1 !important;
	}
}

.portfolioSliderNext{
	right: 10px;
	    padding-left: 3px;
}
.portfolioSliderPrev{
	left: 10px;
}

.shadow {
	@include transition(3s 0.2s);
	opacity: 0;
    background: -moz-linear-gradient(top, transparent 0%, rgba(0,0,0,0.64) 49%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.61) 83%, rgba(0,0,0,0.57) 84%, transparent 100%);
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0,0,0,0.64) 49%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.61) 83%, rgba(0,0,0,0.57) 84%, transparent 100%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.64) 49%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.61) 83%, rgba(0,0,0,0.57) 84%, transparent 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=0 );
    @include transform(skewX(-60deg) translateX(15%));
    @include box-shadow(inset 300px -30px 100px 0px #fff);
    border: 1px solid #fff;
    position: absolute;
    height: 10%;
    bottom: -6%;
    right: 0px;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    width: 120%;
}

.filters{
	text-align: left;
    clear: both;
    width: 100%;
	margin-bottom: 640px !important;
}
.filters button {
    border: 0px;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    font-size: 1.1em;
    padding: 10px 0px;
    width: 100%;
    border-bottom: 1px solid transparent;
    font-weight: lighter;
    text-align: left;
    color: #fff !important;
    &:hover:before{
    	width: 100% !important;
    	opacity: 1 !important;
    }
}

.filters button:before {
	@include transition($t1);
    content: "";
    display: block;
    opacity: 0;
    position: absolute;
    height: 1px;
    width: 0%;
    background: #fff;
    bottom: 0px;
    left: 0px;
}

.filters button.active{
	&:before{
    	width: 100% !important;
    	opacity: 1 !important;
    }
/* 	color: $themeColor !important; */
	/* border-bottom: 1px solid #fff !important; */
} 

/*** Prospectus Page ***/
.ProspectusGroup1,
.ProspectusGroup2{
	opacity: 0;
}

.ProspectusPage{

	#Prospectus2,
	#Prospectus1{
		padding-bottom: 80px;
	}

	.section1{ 
		.left{
			padding-right: 5%;
			.pageTitle{
				margin-bottom: 35px;
				@include transform(translateX(-50px));
			}

			.prospectusContent *{color: rgb(37,37,38);font-weight: lighter;font-size: 1em;}
			.prospectusContent{
				@include transform(translateY(50px));
				font-size: 1.3em;
				a{
					color: $themeColor !important;
				}

			}
			.button{
				font-size: 1.3em;
				margin-left: 40px;
				@include transform(translateX(-50%));
			}
		}
		.right{
			img{
				@include transform(translateX(50px)scale(0.8));
			}
		}
	}

	.section2{
		padding-bottom: 30px;
		.right{
			.pageTitle{
				margin-bottom: 35px;
				@include transform(translateX(50px));
			}
			.prospectusContent *{color: rgb(37,37,38);font-weight: lighter;font-size: 1em;}
			
			.prospectusContent{
				@include transform(translateY(50px));
				font-size: 1.3em;
				a{
					color: $themeColor !important;
				}
			}
			.button{
				font-size: 1.3em;
				@include transform(translateX(-50%));
			    margin-left: 51px;
			}
		}
		.left{
			padding-right: 5%;
			img{
				@include transform(translateX(50px)scale(0.8));
			}
		}
	}
	#Prospectus1.activeIn{
		.ProspectusGroup1{
			opacity: 1 !important;
		}
		img.ProspectusGroup1{
			@include transform(translateX(0px) scale(1) !important);
		}
		.ProspectusGroup1.pageTitle{
			@include transform(translateX(0px) !important);
		}
		.ProspectusGroup1.prospectusContent{
			@include transform(translateY(0px) !important);
		}
	}

	#Prospectus2.activeIn{
		.ProspectusGroup2{
			opacity: 1 !important;
		}
		img.ProspectusGroup2{
			@include transform(translateX(0px) scale(1) !important);
		}
		.ProspectusGroup2.pageTitle{
			@include transform(translateX(0px) !important);
		}
		.ProspectusGroup2.prospectusContent{
			@include transform(translateY(0px) !important);
		}
	}


	#greenBanner{
		opacity: 0;
		@include transition(all 1s 0.5s);
	}
	.activeIn#greenBanner{
		opacity: 1 !important;
	}
	
	
	#Prospectus4{
		padding: 50px 20px 25px 20px;
		background-color: #000;
		.innerSmall{
			padding: 0px !important;
		}
		.pageTitle{
			color: #fff !important;
		}
		*{color: #fff;}

		.subHeading{
		    font-weight: 100;
		    font-size: 1.5em;
		    margin-top: 15px;
		}

		.toggleContent{
			@include border-radius(0px !important);
			border: none !important;
			padding-top: 40px;
			background-color: transparent !important;
			cursor: pointer;
			img{
				@include transition($t1);
				@include transform(rotate(0deg));
			}
		}

		.toggleContent.active{
			img{
				@include transform(rotate(180deg) !important);
			}
		}
		.toggleContentSection{
		    padding: 40px 20px 40px 20px;
		    font-weight: lighter;
		    text-align: left;
		    font-size: 1.4em;
		}
	}
	
	.ProspectusGroup3{
		opacity: 0;
	}
	
	#Prospectus5 img{margin-top: 50px;}
	#Prospectus5.activeIn{
		.ProspectusGroup3{
			opacity: 1 !important;
		}
		img.ProspectusGroup3{
			@include transform(translateX(0px) scale(1) !important);
		}
		.ProspectusGroup3.pageTitle{
			@include transform(translateX(0px) !important);
		}
		.ProspectusGroup3.prospectusContent{
			@include transform(translateY(0px) !important);
		}
	}
}
button.toggleContent:before {
    content: "More";
    position: absolute;
    top: 15px;
    text-transform: uppercase;
    left: 50%;
    opacity: 1;
    color: $themeColor;
    @include transition($t1);
    @include transform(translateX(-50%));
    font-weight: lighter;
}

.prospectusContent *{color: rgb(37,37,38);font-weight: lighter;font-size: 1em;}
.prospectusContent{
	@include transform(translateY(50px));
	font-size: 1.3em;
	a{
		color: $themeColor !important;
	}

}

#greenBanner h2{
    color: #fff;
    font-size: 1em !important;
    font-weight: lighter;
    margin-bottom: 0px !important;
}

.hiddenContent{
	display: none;
}
.toggleContent{
	@include border-radius(0px !important);
	border: none !important;
	padding-top: 40px;
	background-color: transparent !important;
	cursor: pointer;
	img{
		@include transition($t1);
		@include transform(rotate(0deg));
	}
}

.toggleContent.active{
	img{
		@include transform(rotate(180deg) !important);
	}
}
.toggleContentSection{
    padding: 40px 20px 40px 20px;
    font-weight: lighter;
    text-align: left;
    font-size: 1.4em;
}

.activeIn#Prospectus3{
	.infoText{
		opacity: 1 !important;
		@include transform(translateX(-50%) translateY(50%));
	}
}

#Prospectus3{
	/* min-height: 700px; */
	min-height: 880px;
	.bg{
		height: 120%;
	}
	.infoText{
		position: absolute;
		padding: 25px 35px;
		font-size: 1.2em;
		font-weight: lighter;
		left: 50%;
		opacity: 0;
		max-width: 750px;
		bottom: 50%;
		@include transition(all 800ms 1s);
		@include transform(translateX(-50%) translateY(55%));
		color: #2b2b2b;
		background-color: #fff;
		*{color: #2b2b2b;}
		strong{
			font-weight: 500 !important;
		}
	}
}

/*** Websites Page ***/
.WebsitesGroup1,
.WebsitesGroup2{
	opacity: 0;
}
#WebsitesButton1{
				font-size: 1.3em;
				margin-left: 40px;
				@include transform(translateX(-50%));
			}
.WebsitesPage{
	.section1{ 
		.left{
			padding-left: 5%;
			.pageTitle{
				margin-bottom: 35px;
				@include transform(translateX(50px));
			}

			.WebsitesContent *{color: rgb(37,37,38);font-weight: lighter;font-size: 1em;}
			.WebsitesContent{
				font-size: 1.3em;
				@include transform(translateY(50px));
				a{
					color: $themeColor;
				}

			}
			
		}
		.right{
			padding-left: 70px;
			img{
				@include transform(translateX(-50px)scale(0.8));
			}
		}
	} 

	#Websites3{
		.WebsitesGroup3{
			opacity: 0;
		}
	}
	#Websites3.activeIn{
		.WebsitesGroup3{
			opacity: 1 !important;
		}
			.section1 .right img {
		    -webkit-transform: translateX(0px) scale(1) !important;
		    -moz-transform: translateX(0px) scale(1) !important;
		    -ms-transform: translateX(0px) scale(1) !important;
		    transform: translateX(0px) scale(1) !important;
		    max-width: 100% !important;
		}
	}


	#Websites5{

		.pageTitle{
			*{color: $themeColor !important;}
			color: $themeColor !important;
		}

		.ContentSection{
			font-size: 1.2em;
			*{
				color: rgb(37,37,38);
				font-weight: lighter;
			}
			a{
				color: $themeColor !important;
			}
			b{
				font-weight: bold !important;
			}
			#ContactUsButton{
			    font-size: 1.2em !important;
			    @include transform(scale(1) translateX(-50%) !important);
			    margin-left: 58px !important;
			}
		}

		.pageTitle{
			margin-bottom: 35px;
		}
	}

	#Websites6{

		.right{
			padding-left: 5%;
		}

		.ContentSection{
			font-size: 1.2em;
			padding-right: 7%;
			*{
				color: #fff;
				font-weight: lighter;
			}
			a{
				color: $themeColor !important;
			}

			b{
				font-weight: bold !important;
			}
			#ContactUsButton{
			    font-size: 1.2em !important;
			    @include transform(scale(1) translateX(-50%) !important);
			    margin-left: 58px !important;
			}
		}

		.pageTitle{
			color: #fff !important;
		}
	}

	#Websites1.activeIn{
		.WebsitesGroup1{
			opacity: 1 !important;
		}
		img.WebsitesGroup1{
			@include transform(translateX(0px) scale(1) !important);
		}
		.WebsitesGroup1.pageTitle{
			@include transform(translateX(0px) !important);
		}
		.WebsitesGroup1.WebsitesContent{
			@include transform(translateY(0px) !important);
		}
	}

	#Websites7{
		padding: 40px 0px;
		background-image: url("../images/pic16.png");
		background-size: cover;
		background-position: center;
		background-attachment: fixed;
		-webkit-background-attachment: fixed;
		min-height: 100vh;

		.centerWhite{
			padding: 100px 0px;
			width: 100%;
		    max-width: 680px;
			
		}
		.ContentSection{
			background-color: #fff;
			padding: 50px;
			font-size: 1.2em;

			*{
				color: rgb(37,37,38);
				font-weight: lighter;
			}
			a{
				color: $themeColor !important;
			}

			b{
				font-weight: bold !important;
			}
			#ContactUsButton2{

			    font-size: 1.2em !important;
			    margin: 0 auto;
			}
		}

		.pageTitle{
			margin-bottom: 35px;
		}
	}

	#Websites8{
    	padding: 70px 0px;
		.ContentSection{
			    background-color: #fff;
    			padding: 20px 0px 20px 100px;
    			font-size: 1.2em;
			*{
				color: rgb(37,37,38);
				font-weight: lighter;
			}
			b{
				font-weight: bold !important;
			}
			#ContactUsButton2{
			    font-size: 1.2em !important;
			    margin: 0 auto;
			}
		}

		.subTitle{
			font-size: 2.3em;
			font-weight: 100;
			color: #252526;
			margin-bottom: 70px;
			margin-top: 15px;
		}
	}
	.ipadSliderContainerInner{
		margin: 0 auto;
		width: 809px;
		position: relative;
	}
	.ipadSlider{
		position: absolute;
	    top: 57px;
	    left: 68px;
	    width: 669px;
	    height: 506px;
	}

}


	#Websites4{
		padding: 50px 20px 25px 20px;
		background-color: $themeColor;
		.innerSmall{
			padding: 0px !important;
		}
		.pageTitle{
			color: #fff !important;
		}
		*{color: #fff;}

		.subHeading{
		    font-weight: 100;
		    /* font-size: 2em; */
		        font-size: 1.5em;
		    /* margin-top: 15px; */
		}

		.hiddenContent{
			display: none;
		}
		.toggleContent{
			@include border-radius(0px !important);
			border: none !important;
			padding-top: 40px;
			background-color: transparent !important;
			cursor: pointer;
			img{
				@include transition($t1);
				@include transform(rotate(0deg));
			}
		}

		.toggleContent.active{
			img{
				@include transform(rotate(180deg) !important);
			}
		}
		.toggleContentSection{
		    padding: 40px 20px 40px 20px;
		    font-weight: lighter;
		    text-align: left;
		    font-size: 1.4em;
		}
		button.toggleContent:before {
			color: #fff !important;
		}
	}

.BrandingPage{
	#Websites4 {
	    background-color: #000000;
	}
	#SchoolBranding1{
		padding: 100px 0px;
	}
}

.ContentSection{
	font-size: 1.2em;
	*{
		color: rgb(37,37,38);
		font-weight: lighter;
	}
	a{
		color: $themeColor !important;
	}

	b{
		font-weight: bold !important;
	}
	#ContactUsButton2{

	    font-size: 1.2em !important;
	    margin: 0 auto;
	}
}

.PortfolioItemPage #ContactUsButton2{
	@include transform(translateX(-50%));
    margin-left: 47px;
}

#SchoolBrandingSliderContainer{

}
#SchoolBrandingSlider{
	.SliderTitle{
		text-transform: uppercase;
		padding: 20px 0px 0px 0px;
		font-size: 1.4em;
	}
}
.SchoolBrandingPage {
	.section1{
		padding-right: 20px !important;
		padding-left: 20px !important;
	}
}
.hide{
	visibility: hidden;
	position: absolute;
}

.swipeButton{
	display: inline-block;
	color: $themeColor !important;
	padding-right: 40px;
	font-size: 1.5em;
	font-style: italic;
	position: relative;
	font-weight: 100 !important;
	margin-top: 50px;
	display: none !important;
	img{
		position: absolute;
		right: 3px;
		top: 50%;
		@include transform($center);
	}
}

.Sliderprev,
.Slidernext{
	margin: 10px;
	display: inline-block;
	position: relative;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}
.PopularFeatures{
	position: fixed;
	top: 50%;
	right: 0px;
	z-index: 9999;
	font-weight: lighter;
	color: #fff !important;
	text-transform: uppercase;
	@include transition($t1);
	@include transform(translateY(-50%));
	background-color: $themeColor;
	.popularFeaturesToggle{
			padding: 20px 70px 20px 20px;
			width: 100%;
			color: #fff !important;
			position: relative;
			cursor: pointer;
			font-weight: normal !important;
		img{
			right: 20px;
			top: 50%;
			@include transition($t1);
			@include transform(translateY(-50%) rotate(0deg));
			position: absolute;
		}
	}
	.popularFeaturesToggle.active{
		img{
			@include transform(translateY(-50%) rotate(180deg));
		}
	}
	.HiddenList{
		display: none;
		padding: 10px 20px 20px 20px;
		a{
			padding: 5px 0px;
		    border-bottom: 1px solid rgba(255,255,255,0.1);
		    display: block;
		    text-transform: none;
		    font-weight: lighter;
		    font-size: 1em;
		    clear: both;
		    color: #fff;
		    @include transition($t1);
		    &:hover{
		    	color: #000 !important;
		    }
		}
	}
}

.popLink{
	font-size: 1em;
}
/*** Websites Page ***/
/*** SchoolBranding Page ***/
.SchoolBrandingGroup1{
	opacity:0;
}

.BrandingPage,
.SchoolBrandingPage{
	
	#SchoolBranding1 img{
		    max-width: 90%;
	}
	#SchoolBranding2{
		padding: 100px 20px;
		.pageTitle{
			padding: 0px 10%;
			margin-bottom: 30px;
		}
	}
	/*.SchoolBrandingContent{font-size: 1.2em;}*/
	.SchoolBrandingContent *{color: rgb(37,37,38);font-weight: lighter;}
	.section1{ 
		.left{
			.pageTitle{
				@include transform(translateX(-50px));
			}

			
			.SchoolBrandingContent{
				@include transform(translateY(50px));
			}
			.button{
				font-size: 1.3em;
				margin-left: 40px;
				@include transform(translateX(-50%));
			}
		}
		.right{
			img{
				@include transform(translateX(-50px)scale(0.8));
			}
		}
	} 

		#SchoolBranding1.activeIn{
			.SchoolBrandingGroup1{
				opacity: 1 !important;
			}
			img.SchoolBrandingGroup1{
				@include transform(translateX(0px) scale(1) !important);
			}
			.SchoolBrandingGroup1.pageTitle{
				@include transform(translateX(0px) !important);
			}
			.SchoolBrandingContent{
				@include transform(translateY(0px) !important);
			}
		}

	#SchoolBranding3{
		.SchoolBranding3{
			opacity: 0;
		}
	}
	#SchoolBranding1.activeIn{
		.SchoolBrandingGroup3{
			opacity: 1 !important;
		}
		.SchoolBrandingContent{
			@include transform(translateY(0px) !important);
		}
	}
	.SliderTitle {
        text-transform: uppercase;
	    font-weight: lighter;
	    font-size: 1.4em;

	    text-align: center;
	    padding: 0px 10%;
	    margin-bottom: 30px;
	}
	#SchoolBranding4{
	
		padding: 130px 0px;
		background-position: center;
		background-size: cover;
		background-attachment: fixed;
		-webkit-background-attachment: fixed;
		background-image: url("../images/BrandingImage.jpg");
		*{
			color: #fff !important;
		}

		.contentButton{
		/*	position: absolute;
			bottom: 0px;
			left: 40px;*/
		    font-size: 1.25em;
		}

		.content{
		    font-size: 1.25em;
		    font-weight: lighter;
		}
		.darken{
			position: absolute;
		    left: 0px;
		    top: 0px;
		    width: 100%;
		    height: 100%;
		    background-color: rgba(33, 46, 76, 0.35);
		}
	}


	#SchoolBranding5{
		p{
			font-size: 1.6em;
			color: #575c63;
			font-weight: lighter;
		}
	}

	#SchoolBranding6{
		background: #000;
		*{
			color: #fff;
		}


	}
	#SchoolBranding7{
		padding-left: 20px !important;
		padding-right: 20px !important;
		background-color: #edefee;
		padding-top: 90px;

		.section7{ 
		.left{
			.pageTitle{
				@include transform(translateX(-50px));
			}

			
			.SchoolBrandingContent{
				@include transform(translateY(50px));
			}
			.button{
				font-size: 1.3em;
				margin-left: 40px;
				@include transform(translateX(-50%));
			}
		}
		.right{
			img{
				@include transform(translateX(-50px)scale(0.8));
			}
		}
	} 

		
	}

}
.SchoolBrandingGroup2,
.SchoolBrandingGroup3,
.SchoolBrandingGroup7{
	opacity: 0;
}

#SchoolBranding4.activeIn,
#SchoolBranding2.activeIn{
	.SchoolBrandingGroup2{
		opacity: 1 !important;
	}
	.SchoolBrandingGroup3{
		opacity: 1 !important;
	}
}

#SchoolBranding7.activeIn{
	.SchoolBrandingGroup7{
		opacity: 1 !important;
	}
	.pageTitle{
		@include transform(translateX(0px) !important);
	}
	img.SchoolBrandingGroup7{
		@include transform(translateX(0px) scale(1) !important);
	}
	.SchoolBrandingGroup7.pageTitle{
		@include transform(translateX(0px) !important);
	}
	.SchoolBrandingContent{
		@include transform(translateY(0px) !important);
	}
}

.BrandingPage #SchoolBranding2 .pageTitle{
		padding: 0px 0% !important;
		margin-bottom: 30px;
	}

.branding2{
	.section1{padding: 100px 0px;}
}

.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.alignBottom{
	align-self: flex-end;
}

.AboutUsPage{
	.titleRight{
    	font-size: 1.8em;
	    text-align: right;
	    font-weight: lighter;
	    margin-bottom: 17px;
	    h2{
		    font-size: 1em;
		    color: #333;
		    font-weight: lighter;
		}
	}

	.whiteBannerSection{
		*{color: $themeColor;}
		padding: 50px 0px;
	    font-size: 1.9em;
	    line-height: 1.4em;
	    font-weight: lighter;
		.innerCenter{
			padding: 0px 33px;
		}
	}

	.topSection{
		padding-top: 46px;
	}

	.section1{
		padding: 0px 0px;
		background-color: $themeColor !important;
		
		.AboutBlock{
			opacity: 0;
			margin-bottom: 30px;
			*{
				color: #fff;
				font-weight: 100;
			}
			a{
				color: $themeColor !important;
			}
		}
		.col-4{
			text-align: center;
			img{
				position: absolute;
		    	max-width: 97px !important;
			    left: 50%;
			    top: 50%;
			    @include transform($center);
			}


			
		}
		.col-8,
		.col-4{
			padding: 20px;
		}
	}
	.purpleSection{
		padding: 80px 30px 10px 30px;
		background-color: #fff;
		.innerCenter {
		    max-width: 1023px;
		}
		.pageTitle{
			color: $themeColor !important;
			font-size: 3.8em;
			margin-bottom: 50px;
		}
		.title{
			color: $themeColor !important;
			font-weight: lighter;
			font-size: 1.3em;
			text-transform: uppercase;
		}
		.content{
			color: #000 !important;
			font-weight: lighter;
			font-size: 1.3em;
			line-height: 1.3em;
		}

		.bx-pager-link.active{
			background-color: $themeColor !important;
		}
	}

	#CareersSection{
		padding: 80px 0px;

		.content{
		    font-size: 1.5em;
		    line-height: 1.67em;
		    font-weight: lighter;
		    margin-bottom: 70px;
		}
	}

	#FundraisingSection{
		background-color: #464647 !important;
		padding: 80px 20px;
		.content{
			padding-right: 22%;
		}

		.pageTitle{
			margin-bottom: 40px;
		}

		p {
		    color: #fff;
		    font-weight: lighter;
		    font-size: 1.2em;
		}

	}
	.bannerTitle{
		/* tex-transform: none !important; */

	}
}

.bannerTitle{
	b{
		color: #fff;  
	}
}

.twhite{
	color: #fff !important;
}

#AboutUs1.activeIn .AboutBlock{
	opacity: 1 !important;
}

#FundraisingSection,
#AboutUs3{
	.pageTitle{
		opacity: 0;
		@include transition(all 1s);
		@include transform(translateY(20px));
	}
}
#FundraisingSection{
	.left{
		opacity: 0;
		@include transform(translateX(-50px) scale(0.8));
		@include transition(all 1s 500ms);
	}
	.right{
		opacity: 0;
		@include transform(translateX(50px)  scale(0.8));
		@include transition(all 1s 1s);
	}
}

#aboutUsSLiderContainer{
	opacity: 0;
	@include transform(translateX(-50px));
	@include transition(all 1s 500ms);
	.content{
		*{color: #000 !important;}
	}
}

#AboutUs3.activeIn{
	#aboutUsSLiderContainer{
		opacity: 1 !important;
		@include transform(translateX(0px) !important);
	}
	.pageTitle{
		opacity: 1 !important;
		@include transform(translateY(0px) !important);
	}
}
.AboutUsGoupCareers{
	opacity: 0;
	@include transform(translateY(20px));
}

#CareersSection.activeIn{
	.AboutUsGoupCareers{
		opacity: 1 !important;
		@include transform(translateY(0px) !important);
	}
}


#FundraisingSection.activeIn{
	.pageTitle{
		opacity: 1 !important;
		@include transform(translateY(0px) !important);
	}

	.left{
		opacity: 1;
		@include transform(translateX(0px) scale(1) !important);
	}
	.right{
		opacity: 1;
		@include transform(translateX(0px) scale(1) !important);
	}
}



.greybg{
	background-color: #464647;
}


.pageLink{
	opacity: 0;
	display: block;
	padding: 10px 20px 0px 20px;
	float: left;
    width: 25%;
    position: relative;
    border-right: 1px solid rgba(255, 255, 255, 0.23);

    h3{
    	color: #fff;
	    font-weight: 100;
	    font-size: 1.2em;
	    margin-bottom: 0px !important;
	    padding: 15px 0px 0px 0px;
	    text-align: center;
    }
    a{
    	position: absolute;
    	top: 0px;
    	left: 0px;
    	width: 100%;
    	height: 100%;
    }
    img{
    		@include transition($t1);
    		@include transform(rotate(0deg));
    	}
    &:hover{
    	img{
    		@include transform(rotate(10deg) !important);
    	}
    }
}



.section3{
	text-align: center;
	background-color: #464647 !important;
	.innerSmall{
		padding: 40px 20px !important;
	}
}
.section3.activeIn{
	.pageLink{
		opacity: 1 !important;
	}
}

.pageLink.pageLink-0{
	border-right: none !important;
}
.bannerTitle{
	*{}
	background-color: $themeColor;
	color: #fff;
    font-size: 2.1em;
    font-weight: 200;
    text-transform: uppercase;
    padding: 30px 20px;
    a{
    	font-size: inherit;
    	color: #fff !important;
    }
}

.MarketingPage{

	.pageTitle.MarketingGroup6,
	.pageTitle.MarketingGroup2{
		padding: 0px 50px  0px  50px;
	}
	#Marketing6,
	#Marketing2{
		padding: 80px 0px 0px 0px;
	}
	#Marketing6,
	#Marketing2,
	#Marketing1{
		.titleSection{
			p{
				font-size: 1.6em;
				color: #575c63;
				font-weight: lighter;
			}
		}

		.ContentSection{
			padding: 0px 50px  50px  50px;
			a{
					color: $themeColor !important;
				}
		}
		.ContentSection.left{
			@include transform(translateX(-50px));
		}
		.ContentSection.right{
			@include transform(translateX(50px));
		}

	}
	#Marketing3{
		padding: 40px 20px;
		background-color: $themeColor !important;
		*{
			color: #fff !important;
		}
		p{
			font-size: 1.6em;
			font-weight: 100 !important;
		}
	}
	#Marketing4{
		padding-bottom: 20px;
		img{max-width: 45px;}
		.toggleContent {
    		padding-top: 25px;
		}
	}
	#Marketing5{
		.col-2{
			opacity: 0;
			padding-bottom: 16.66%;
			background-size: cover;
			background-position: center;
			background-image: url(../images/pic22.png);
			a{
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
		}
		.Backwards.col-2{
			@include transform(translateY(50px) translateX(-50px) scale(0.5));
		}
		.Forwards.col-2{
			@include transform(translateY(50px)  translateX(50px) scale(0.5));
		}
	}
	#Marketing5.activeIn{
		.Backwards.col-2{
			@include transform(translateY(0px) translateX(0px)  scale(1) !important);
		}
		.Forwards.col-2{
			@include transform(translateY(0px)  translateX(0px)  scale(1) !important);
		}
		.col-2{
			opacity: 1 !important;
		}
	}
}

.MarketingGroup6,
.MarketingGroup2,
.MarketingGroup1{
	opacity: 0;
}
.titleSection.MarketingGroup6,
.titleSection.MarketingGroup2,
.titleSection.MarketingGroup1{
	@include transform(translateY(20px));
}
#Marketing6.activeIn,
#Marketing1.activeIn,
#Marketing2.activeIn{
	.MarketingGroup2,
	.MarketingGroup6,
	.MarketingGroup1{
		opacity: 1 !important;
	}

		.ContentSection.right,
		.ContentSection.left{
			@include transform(translateX(0px) !important);
		}
	.titleSection.MarketingGroup6,
	.titleSection.MarketingGroup1,
	.titleSection.MarketingGroup2{
		@include transform(translateY(0px) !important);
	}
}

.CallUsButton{
	background-color: $themeColor;
	display: inline-block;
	padding: 10px 15px;
	color: #fff;
	font-size: 1.7em;
	span{
		font-weight: lighter;
		color: #fff;
	}
	b{
		color: #fff;
	}
	*{
		color: #fff !important
	}
}

/*** Page Loading ***/
.pace-running{
	overflow: hidden;
}

/*** Page Loading Complete ***/
.pace-done{
	.Loading{
		height: 0%;
		opacity: 0;
	}
}

.pagePadding{
    padding-top: 200px !important;
}


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/Reddoorimages/bg.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}
}



.centerText{
	position: absolute;
	padding: 17px 30px;
	font-size: 2em;
	font-weight: lighter;
	left: 50%;
	bottom: 20%;
	@include transform(translateX(-50%));
	color: #fff;
	background-color: $themeColor;
	*{color: #fff;}
	strong{
		font-weight: 500 !important;
	}
}

.bg{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	@include background-cover(url("../images/Reddoorimages/bg.jpg"));
}

/*** Header ***/
header{
	padding: 0px 40px;
	width: 100%;
    top: 0px;
    z-index: 9999;
    position: fixed;
        background-color: #000;
    perspective: 1000px;
    @include transform(translateY(0%));
    @include box-shadow(0px 0px 6px rgba(41, 56, 90, 0.21));

    .headerDecoration{
    	display: none;
    	position: absolute;
    	left: 0px;
    	width: 100%;
    	background-color: #eee;
    	height: 10px;
    	bottom: 0px;
    	opacity: 1;
    	@include transition($t1);
    }

	.innerHeader{
		float: left;
		width: 100%;
		position: relative;
		min-height: 133px;
		@include transition($t1);
	}

	#Logo{
        width: auto;
		display: inline-block;
	    position: absolute;
	    top: 50%;
	    right: 0px;
	    width: 119px;
	    @include transition($t1);
	    @include transform(translateY(-50%));
	    img{
	    	width: 95%;
			@include transition($t1);
	    }

	    overflow: hidden;
	    h1{
	    	position: absolute;
	    	bottom: -184px;
	    }
	}

	#DesktopNavigation{
		position: absolute;
		bottom: 0px;
		width: 100%;
		left: 0px;
		a:first-of-type{
			margin-left: 0px !important;
		}
		a{
			display: inline-block;
			font-weight: lighter;
			padding: 10px 0px 15px 0px;
			margin: 10px;
			color: #fff;
			margin-right: 20px;
		}
		a:first-of-type{
			margin-left: 0px !important;
		}
	}

	.navHover{
		position: absolute;
		bottom: 0px;
		height: 10px;
		opacity: 0;
		@include transition(all 1s);
		left: 0px;
		background-color: $themeColor;

	}
}

.down{
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding: 8px 20px;
	z-index: 5;
	background-color: $themeColor;
	@include transform(translateY(0%));
	img{
		width: 25px;
	}
}

.scrollNav{
	header{
		.innerHeader{
			min-height: 75px;
		}
		#Logo {
			top: 53%;
		    width: 40px;
		}
		.topLinks{
			margin-top: -40px;
			opacity: 0;
			a{
				@include transform(scale(0.5));
			}
		}
	}
	.callus{
		opacity: 0;
		@include transform(translateY(100%) rotateX(-90deg));
	}
}


.bannerText{
	position: absolute;
	
	h1,
	span{
		@include transform(scale(1));
		color: #fff;
		display: block;
		font-size: 2.5vw;
		text-transform: uppercase;
		font-weight: lighter;
		@include text-shadow(0px 0px 28px rgba(0,0,0,0.5));
	}
	
	bottom: 51vh;
	text-align: center;
	left: 50%;
	width: 80%;
	@include transform(translateX(-50%));
	z-index: 1;
}

.bottonContainer{
	position: absolute;
	bottom: 100px;
	left: 50%;
	@include transform(translateX(-50%));
}
.button{
    padding: 12px 0px 15px 0px;
    display: inline-block;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	@include transform(scale(1));

	span{
		display: block;
		text-align: center;
		width: 100%;
		font-weight: lighter;
		color: #fff;
		@include transition($t1);
		letter-spacing:0px;
	}

	.bottomLine{
	    position: absolute;
	    bottom: 0px;
	    left: 50%;
	}
	.topLine{
		position: absolute;
	    top: 0px;
	    left: 50%;
	}
	.bottomLine,
	.topLine{
		width: 220px;
    	height: 20px;
    	@include transform(translateX(-50%))
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}

	&:hover{
		span{
			letter-spacing:2px;
		}
	}
}
a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

.callus{
	font-weight: lighter;
	position: absolute;
	bottom: 0px;
	right: 40px;
	background-color: $themeColor;
	padding: 10px 20px;
	@include transition($t1);
	@include transform(translateY(100%) rotateX(0deg));
	transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%;
	-o-transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-moz-transform-origin: 0% 0%;
	backface-visibility: none;
	-webkit-backface-visibility: none;
	-o-backface-visibility: none;
	-ms-backface-visibility: none;
	-moz-backface-visibility: none;
	color: #fff;
	font-size: 1.2em;
	strong{
		font-weight: 400 !important;
		color: #fff !important;
	}
	*{
		color: #fff !important;
	}
}

.topLinks{
	opacity: 1;
	position: absolute;
	top: 0px;
	margin-top: 0px;
	@include transition($t1);
	a{
		width: 35px;
	    height: 35px;
	    margin-right: 2px;
	    position: relative; 
		display: inline-block;
		vertical-align: top;
		@include transition($t1);
		@include transform(scale(1));
		&:hover{
			height: 40px;
		}
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform($center);
		}
	}
}
.search{
	img{width: 20px !important;}
	background-color: #fff;
}
.mail{
	img{width: 20px !important;}
	background-color: #4e565c;
}
.twitter{
	img{width: 20px !important;}
	background-color: #20baed;
}
.facebook{
	img{height: 20px !important;
	width: auto !important;}
	background-color: #4679ae;
}
.google{
	img{width: 20px !important;}
	background-color: #eb302e;
}

.circleGrad{
	position: absolute;
	top: 0px;
	width: 100%;
	height: 100%;
	left: 0px;
	background: transparent; /* For browsers that do not support gradients */
	background: -webkit-radial-gradient(rgba(44, 44, 44,0), rgba(44, 44, 44,1)); /* Safari 5.1-6.0 */
	background: -o-radial-gradient(rgba(44, 44, 44,0), rgba(44, 44, 44,1)); /* For Opera 11.6-12.0 */
	background: -moz-radial-gradient(rgba(44, 44, 44,0), rgba(44, 44, 44,1)); /* For Firefox 3.6-15 */
	background: radial-gradient(rgba(44, 44, 44,0), rgba(44, 44, 44,1)); /* Standard syntax */
}

#SideNav{
	position: fixed;
	top: 50%;
	left: 50px;
	@include transform(translateY(-50%));
	z-index: 5996;

	img{
		width: 14px;
		margin-right: 4px;
		vertical-align: middle;
		display: inline-block;	
	}

	.slink{
		color: #000;
	}
	.dot{
		display: inline-block;
		width: 13px;
		height: 13px;
		margin-right: 5px;
		@include border-radius(50%);
		@include transition($t1);
		background-color: #c2c2c1;
		vertical-align: middle;
	}
	.sectionTitle{
		@include transition($t1);
		opacity: 0;
		font-size: 0.8em;
		vertical-align: middle;
		display: inline-block;
		color: #999 !important;
		margin-top: 2px;
	}
	.slink{
		padding: 0px 5px;
		cursor: pointer;

		&:hover{
			.sectionTitle{
				color: #999 !important;
				opacity:  1 !important;
			}
		}
	}
	.slink.active{
		
		.sectionTitle{
			color: #999 !important;
			opacity: 1;
		}
		.dot{
			background-color: $themeColor !important;
		}
	}
}

.tgrey{
	color: #595f61;
}
.tgreen{
	color: $themeColor !important;
	*{color: $themeColor !important;}
}

/*** Header ***/

#home1{
	padding: 100px 0px
}
.group1{
	opacity: 1;
}

.group2{
	opacity: 1;
	float: right;
	clear: both;
	width: 200px !important;
	@include transform(translateX(0%));
	&:hover{
		width: 210px !important;
	}
}

/*** Loading ***/
.Loading{
	overflow: hidden;
	height: 100vh;
	.group1{
		opacity: 0 !important;
	}
	.group2{
		opacity: 0 !important;
		@include transform(translateX(100%) !important);
	}

	.bannerText{
		span{
			@include transform(scale(0.8) !important);
		}
	}
	.button{
		@include transform(scale(0.8) !important);
	}
	.down{
		@include transform(translateY(100%));
	}
	header{
		 @include transform(translateY(-100%));
	}
	.callus{
		opacity: 0;
		@include transform(translateY(100%) rotateX(-90deg));
	}
}
/*** Loading ***/
/*** Intro ***/
#Intro{
	position: fixed;
	z-index: 9999;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #fff;
	img{
		opacity: 0;
		@include transition(all 0.8s 0s);
	}
	.introCenter{
		position: absolute;
		left: 50%;
		top: 50%;
		@include transform($center);
	}
	.IntroText{
		span{
			text-transform: uppercase;
			opacity: 0;
			font-size: 2.3vw;
			color: #58595b;
			font-weight: lighter;
		}
	}
}

/*.zoom{
	@include transition(transform 20s);
	@include transform(scale(1.2));
}

.zoomBg{
	position: absolute;
	top: 0px;
	width: 100%;
	height: 100%;
	left: 0px;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}*/

.IntroActive{
	#Intro{
		img{
			opacity: 1 !important;
		}
		.IntroText{
			span{
				opacity: 1 !important;
			}
		}
	}
	
}
.Loading{
	.zoom{
		@include transform(scale(1) !important);
	}
}


.hoverEffect{
	@include transition($t1);
	&:hover{
		background-color: #9eb554 !important;
	}
}

/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}
/*** Footer ***/
footer{
	    background: #000;
    padding: 50px 40px;
    *{color: #fff;}
	.footerContact{
		font-size: 2em;
		font-weight: lighter;
	}
	.footerGreen{
		font-weight: 400;
		color: $themeColor;
	}
	.contentButton span {
    color: #fff !important;
}

	.copyright{
		margin-top: 15px;
		font-size: 0.8em;
	}

	.up{
		position: absolute;
		cursor: pointer;
		right: 0;
		top: 50%;
		padding: 25px;
		background-color: $themeColor;
		@include transition($t1);
		@include transform(translateY(-50%));
		img{
			max-width: 30px;
		}
		&:hover{
			background-color: #58595b !important;
		}
	}
	#FooterButton{
		position: absolute;
		right: 179px;
		font-size: 1.3em;
		top: 50%;
		padding: 18px 0px 24px 0px !important;
		@include transform(translateY(-50%) translateX(50%));
	}
}

#DesktopNavigation li {
    display: inline-block;
    .subMenu{
    	display: none;
    	max-width: 600px;
    	padding: 0px 10px 13px 10px;
    	background-color: $themeColor;
   		position: absolute;
   		z-index: 9999;
   		&:before{
   			    opacity: 0;
   				content: '';
   				position: absolute;
   				display: block;
   				top: -10px;
   				z-index: 9999;
   				left: 0px;
   				width: 100%;
   				height: 10px;
   		}
   		*{
   			color: #fff;
   		}
   		a{
   			padding: 10px 0px 7px 0px;
   			margin: 0px 10px !important;
   			font-size: 0.9em;
   			position: relative;
   			&:after{
   				@include transition($t1);
   				opacity: 0;
   				content: '';
   				position: absolute;
   				display: block;
   				bottom: 0px;
   				left: 0px;
   				width: 100%;
   				height: 5px;
   				background-color: #fff;
   			}
   			&:hover{
				&:after{
					opacity: 1 !important;
   				}
   			}
   		}
    }
    &:hover{
    	.subMenu{
    		display: block !important;
    	}
    }
}

.CasePageImageOne{
	max-width: 70%;
}

.CasePage{
	.PageControls {
	    position: relative;
	    width: 100%;
	    float: left;
	    clear: both;
	    margin-bottom: 30px;
	}

	.CasePageImageTwo{
	    position: absolute;
	    left: -90px;
	    width: 131%;
	    max-width: none;
	}

	.caseBottomRight{
		margin-top: 50px;
		min-height: 280px;

		.button{
		   /*  bottom: 100px; */
		    position: absolute;
		    right: 66px;
		    left: auto;
		    @include transform(translateX(50%) !important);
		}
	}
}

#Marketing4 button.toggleContent:before {
    z-index: 99;
    top: 7px;
    color: $themeColor;
}
button.toggleContent.active:before{
	content:"LESS";
}
.MarketingPage #Marketing4 {
    padding-top: 20px;
}

.invertNav{
	.PopularFeatures {
	    background-color: #8da444;
	    a:hover{
	    	color: #464647 !important;
	    }
	}
}

#SearchResults li {
    width: 100%;
    display: block;
    float: left;
    clear: both;
    padding: 20px 0px;
    border-bottom: 2px solid #eee;
    font-size: 0.8em;
    .readMoreLink{
    	color: $themeColor !important;
    }
}
/*** Edit ***/
.pageTitle{
	*{
		line-height: 1.2em;
	}
	p{
	    font-size: 0.8em;
	}
}

#Websites6{
	.pageTitle{
		margin-bottom: 0px !important;
	}
}
#aboutUsSLiderContainer,
#TestimonialsSliderContainer{
	.bx-controls{
		position: absolute;
		opacity: 1;
		z-index: 80;
	}
}

#MemberLoginForm_LoginForm_action_dologin {
    margin-bottom: 20px;
    left: 55px;
}

.AboutUsGoupCareers{
	.button{
	    font-size: 1.5em !important;
	}
}

.hidden{
	opacity: 0 !important;
}
/*** Edit ***/

#Websites1,
#Websites2,
#Websites3,
#Websites4,
#Websites5,
#Websites6,
#Websites7,
#Websites8,
#Websites1{
	overflow: hidden;
}

.bgCover{
	background-size: cover !important;
}

#micro-data{display: none;}

.topLinks a{
    /* background-image: url(/themes/FSEDesign/images/top-Links.png); */
    background-position: center;
    background-repeat: no-repeat;
}
.search{
    background-position: 139px;
    background-image: url(../images/Reddoorimages/search.png);
}
.mail{
    background-position: 139px;
    background-image: url(../images/Reddoorimages/mail.png);
}
.twitter{
    background-position: 105px;
    background-image: url(../images/Reddoorimages/twitter.png);
}
.facebook{
    background-position: 71px;
    background-image: url(../images/Reddoorimages/facebook.png);
}
.google{
    background-position: 35px;
    background-image: url(../images/Reddoorimages/googleplus.png);
}
.topLinks a img{
    display: none;
}

.blackBG{
	background-color: #000 !important;
}
.RedBG{
	background-color: $themeColor !important;
}
.RedTitle{
	color: $themeColor !important;
}

/*** Abourt us ***/
.imageLeft{
	background-size: cover;
	background-position: center;
	background-image: url(../images/Reddoorimages/innerpages/img5.png);
}

.aboutUsTitle{
	width: 100%;
	text-align: center;
	padding-bottom: 30px;
	color: $themeColor !important;
	*{color: $themeColor !important;}
}

.aboutUsRight{
	padding-left: 5% !important;
	padding-right: 8% !important;
	padding-bottom: 100px !important;
	padding-top: 100px !important;
}


.thumbContainer{
	margin: 0 auto;
	width: 150px;
	height: 150px;
	background-color: #000;
	padding: 10px;
	@include border-radius(50%);
	img{
		width: 100%;
	}
}

@media all and (min-width: 1822px){
	#Intro .IntroText span {
	    font-size: 2.58em !important;
	}
}


/** Image Boxes **/
.imageBox{
	.col-12{
		padding-bottom: 100%;
		background-size: cover;
		background-position: center;
	}	
}

/*** Image Sections ***/
.TextSection{ 
	.left{
		padding-left: 20px !important;
		padding-right: 20px !important;
		.pageTitle{
			@include transform(translateX(-50px));
		}

		
		.ImageGroupContent{
			@include transform(translateY(50px));
		}
		.button{
			font-size: 1.3em;
			margin-left: 40px;
			@include transform(translateX(-50%));
		}
	}
	.right{
		padding-left: 20px !important;
		padding-right: 20px !important;
		.pageTitle{
			@include transform(translateX(50px));
		}

		
		.ImageGroupContent{
			@include transform(translateY(50px));
		}
		.button{
			font-size: 1.3em;
			margin-left: 40px;
			@include transform(translateX(-50%));
		}
	}
} 



.ImageSectionLeft{ 
	.left{
		padding-left: 20px !important;
		padding-right: 20px !important;
		.pageTitle{
			@include transform(translateX(-50px));
		}

		
		.ImageGroupContent{
			@include transform(translateY(50px));
		}
		.button{
			font-size: 1.3em;
			margin-left: 40px;
			@include transform(translateX(-50%));
		}
	}
	.right{
		padding-left: 20px !important;
		padding-right: 20px !important;
		img{
			@include transform(translateX(-50px)scale(0.8));
			max-width: 80%;
		}
	}
} 
.ImageSectionRight{ 
	.left{
		padding-left: 20px !important;
		padding-right: 20px !important;
		.pageTitle{
			@include transform(translateX(-50px));
		}

		
		.ImageGroupContent{
			@include transform(translateY(50px));
		}
		.button{
			font-size: 1.3em;
			margin-left: 40px;
			@include transform(translateX(-50%));
		}
	}
	.right{
		padding-left: 20px !important;
		padding-right: 20px !important;
		img{
			@include transform(translateX(-50px)scale(0.8));
			max-width: 80%;
		}
	}
} 

.ImageGroup1{
	opacity: 0 ;
}
.ImageGroup2{
	opacity: 0 ;
}

.TextSection.activeIn,
.ImageSectionLeft.activeIn,
.ImageSectionRight.activeIn{
	.ImageGroup2{
		opacity: 1 !important;
	}
	.ImageGroup1{
		opacity: 1 !important;
	}
	img.ImageGroup1{
		@include transform(translateX(0px) scale(1) !important);
	}
	.ImageGroup1.pageTitle{
		@include transform(translateX(0px) !important);
	}
	.ImageGroup2.pageTitle{
		@include transform(translateX(0px) !important);
	}
	.ImageGroupContent{
		@include transform(translateY(0px) !important);
	}
}

.sectionPadding{padding: 100px 0px;}

.RedBg{
	background-color: $themeColor !important;
	*{
		color: #fff !important;
	}
}


.callustoday{
	display: inline-block;
	color: #fff;
	background-color: #000;
	font-size: 1.5em;
	font-weight: lighter;
	padding: 10px 40px 13px 40px;
	margin-bottom: 50px;
	*{
		color: #fff;
	}
	b{
		font-weight: bold;
	}
}

/*** Estate Agents ***/
#EstateAgents1{
	/*padding: 100px 20px;*/
	    padding: 243px 20px 100px 20px;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	background-image: url(../images/bg.jpg);
	/*min-height: 50vh;*/
	.darken{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
	}
	.inner{
		max-width: 600px;
		color: #fff;
		font-weight: lighter;
		*{color: #fff;}
	}
}

.lightGreyBG{
	background-color: #f1f1f1 !important;
}
.portfolioLeft{
	background-color: $themeColor;
}

/**** Temporary Styling changes **/
.PortfolioPage .portfolioLeft{
	display: none;
}
.PortfolioItemContainerOuter{
	width: 100%;
}

/*** Pop Up ***/
.popMeBg{
	display: none !important;
	position: fixed;
	width: 100%;
	top: 0px;
	left: 0px;
	height: 100%;
	background-color: rgba(23, 23, 23, 0.8);
	z-index: 9999;
}
#popup{
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	@include transform($center);
	width: 70%;
	min-width: 80%;
	overflow: hidden;
	z-index: 9999;
	padding: 10px;
	background-color: #fff;
	@include border-radius(5px);
	@include box-shadow(0px 0px 10px 0px rgba(0,0,0,1));
}
.popupImage{
	background-size: cover;
	background-position: center;
	width: 100%;
	padding-bottom: 48%;
	float: left;
	@include border-radius(3px);
	@include transition($t1);
}
.popPrev,
.popNext{
    cursor: pointer;
    color: #656565;
    padding-top: 3px;
    display: inline-block; 
}

.closePopMe{ 
    cursor: pointer;
	position: absolute;
    top: 0px;
    right: 0px;
    background-color: #fff;
    width: 33px;
    text-align: center;
    padding-top: 4px;
    border-radius: 0px 4px 0px 8px;
    font-size: 1.4em;
    height: 33px;
    z-index: 999;
	i{
		color: #656565;
	}
}
.hideButton{
	display: none !important;
}
.p2{
	padding: 2px;
}

.PortfolioTitle{
	.SliderTitle{
		text-align: left !important;
	}
}

.dn{
	display: none !important;
}

.innerCenter * li{	
	list-style: disc !important;
	/* text-align: left !important; */
}
.innerCenter * ul{	
	padding-left: 23px !important;
}



.ContentFormat{
	line-height: 1.5em !important;
    font-size: 1.1em !important;
	color: #252526;
    font-weight: lighter;
	*{
		color: #252526;
    	font-weight: lighter;
	}
}
/*
.BrandingPage{
	.ContentFormat{
   	  font-size: 0.9em;
	}
}
*/






/* -------------------- ExitPop Up Page -------------------- **/

.mainContent{
	*{
	font-weight: lighter;
	}
}
.GreenBlock{
	padding: 50px 10%;
	background-color: #d32237;
	*{
		color: #fff;
	}
	.col-4{
		padding: 20px;
	}
}
.GreenBlockTitle{
	text-align: center;
	color: #fff;
	font-size: 1.3em;
	padding-bottom: 20px;
	&:after{
		background-color: #fff;
		content: '';
		height: 2px;
		bottom: 0px;
		position: absolute;
		width: 150px;
		display: block;
		left: 50%;
		@include transform(translateX(-50%));
	}
}
.GreenBlockContent{

}
.GreenBlockSmallTitle{
	span{
		display: inline-block;
		padding-bottom: 10px;
		margin-bottom: 10px;

		border-color: transparent;
	    background-image: linear-gradient(#666666, #666666), linear-gradient(#dddddd, #dddddd);
	    background-size: 0 4px, 100% 4px;
	    background-repeat: no-repeat;
	    background-position: left calc(100% + 5px);
	    transition: background-size 250ms;
	    border-bottom: solid 3px #fff;
	    position: relative;
	    color: inherit;
	    &:hover{
	    	background-size: 100% 4px, 100% 4px;
	    	border-color: transparent;
		}
	}
}
.LineTitle{
	text-transform: none;
}
.GreenBlockSmallContent{
	padding-bottom: 30px;
	line-height: 1.7em;
	font-weight: lighter;
	border-bottom: 1px solid #fff;
}
.BlockSection{

}
.ImageRight{
	position: absolute;
	background-size: cover;
	background-position: center;
	height: 100%;
	width: 50%;
	left: 50%;
	background-image: url(../images/bg.jpg);
}
.ImageLeft{
	position: absolute;
	background-size: cover;
	background-position: center;
	height: 100%;
	width: 50%;
	left: 0px;
	background-image: url(../images/bg.jpg);
}
.RightBlock{
	float: right;
}
.InnerBlock{
	line-height: 1.7em;
	*{
		color: #484848;
	}
	background-color: #f5f5f5;
	padding: 8%;
}
.BlockTitle{
	line-height: 1.2em;
	color: #d32237;
	font-weight: bolder;
	font-size: 2em;
	padding-bottom: 35px;
	text-transform: uppercase;
}
.BlockContent{

}
.BlockContentHidden{

}
.BlockbtnContainer,
.BlockButtonContainer{
	a{
		position: relative;
		display: inline-block;
		border: 2px solid #d32237;
		font-size: 0.8em;
		padding: 10px 45px;
		text-transform: uppercase;
		margin-top: 20px;

		i{
			position: absolute;
			right: 15px;
			top: 50%; 
			display: none;
			@include transform(translateY(-50%));
		}
		@include transition($t1);
		*{
			color: #484848;
			@include transition($t1);
		}
		&:hover{
			color: #fff !important;
			background-color: #d32237 !important;
			*{
				color: #fff !important;
			}
		}
	}
}
.ExitPageToggleButton a i{
	display: block;
}
.BottomWideImage{
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	padding: 40px;
}
.BottomImageBoxInner{
	float: left;
	width: 45%;
	background-color: #fff;
	line-height: 1.7em;
	*{
		color: #484848;
	}
	    padding: 3%;
}
.BottomBoxTitle{
	color: #d32237;
	line-height: 1.2em;
	font-weight: bolder;
	font-size: 2em;
	padding-bottom: 35px;
	text-transform: uppercase;
}
.BottomBoxContent{

}
.BottomBoxButtonContainer{
	a{
		display: inline-block;
		padding: 15px 30px;
		border: 1px solid #d32237;
		text-transform: uppercase;

		
	}
}

.NormalLink{
	i{
		display: none;
	}
}

.BlockContentHidden{
	display: none;
}

.UppercaseTitle{
	text-transform: uppercase;
}

.BottomLinkBoxes{
}
.bottomLink{
	padding-bottom: 33.33%;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	.innerBottomBoxHover{
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0;
		width: 90%;
		padding: 8%;
		height: 90%;
		background-color: #d32237;
		@include transition($t1);
		@include transform($center scale(0.8));
		*{
			color: #fff;
		}
		.BottomLinkTitle{
			color: #fff;
			text-transform: uppercase;
		}
		.BottomLinkContent{
			line-height: 1.3em;
			padding-bottom: 30px;
			border-bottom: 1px solid #fff;
			font-weight: lighter;
		}
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	&:hover{
		.innerBottomBoxHover{
			opacity: 1 !important;
			@include transform($center scale(1));
		}
	}
}


.TitleStyle2{
	color: #d32237 !important;
	margin-bottom: 40px;
	line-height: 1.1em;
	font-weight: bold;
	text-transform: uppercase;
}
.ContentSectionStyle{
	padding: 100px 20px;
	background-color: #f6f5f6;
	.inner{
		width: 700px;
		max-width: 100%;
		line-height: 1.6em;
		*{
			color: #444444;
		}
	}
}

.BottomLinkInner{

}


#ExitPopUp{
	position: fixed;
	width: 400px;
	max-width: 100%;
	padding: 20px;
	top: 50%;
	left: 50%;
	@include transform($center);
	z-index: 9999;
	background-color: #fff;
}


@media all and (max-width: 1040px)  {
	.GreenBlock {
    	padding: 50px 10px;
	}
}
@media all and (max-width: 768px)  {
	.GreenBlock {
		.col-4{
			width: 100%;
		}
	}
	.BlockSection{
		padding-top: 300px;
		.ImageRight,
		.ImageLeft{
			top: 0px;
			left: 0px;
			height: 300px;
			width: 100% !important;
		}
	}
	.BlockSection .col-6{
		width: 100% !important;
	}
	.BottomImageBoxInner{
		width: 100% !important;
	}
	.BottomWideImage{
		padding: 0px !important;
	}

	.bottomLink{
		width: 100% !important;
		padding-bottom: 0px;
		height: auto;
	}
	.innerBottomBoxHover{
		position: relative !important;
		float: left !important;
		width: 100% !important;
		height: 100% !important;
		top: auto !important;
		left: auto !important;
		opacity: 0.9 !important;
		@include transform(translate(0px,0px) scale(1) !important);
	}
}
@media all and (max-width: 600px)  {
	.TitleStyle2,
	 .BlockTitle{
	 	font-size: 1.3em;
	 }
}

@media all and (max-width: 1430px)  {

}




.PortfolioPage{
	.PortfolioItem{
		.hover{
			@include transform($center scale(0.8) !important);
			background-color: #d32237;
			top: 50% !important;
			bottom: auto !important;
			left: 50% !important;
			width: 90%;
			height: 90%; 
			opacity: 0;
			.center{
				padding-bottom: 20px;
				border-bottom: 1px solid #fff;
			}
		}
		 .hover .part-1{
			font-size: 1.2em;
			text-align: left;
			border-bottom: none !important;
		}
		&:hover{
			.hover{
				@include transform($center scale(1) !important);
				opacity: 1 !important;
			}
		}
	}
}


.ContactPagePadding{
	padding-top: 130px !important;
}

.ContactForm{
	background-color: #f6f5f6;
	.ContentForm{
		padding-top: 50px;
	}
	.pageTitle{
		font-weight: bolder;    
		font-size: 2em;
	}
}

/* -------------------- ExitPop Up Page -------------------- **/

/* -------------------- Alterations 12/11/2017 -------------------- **/
.QuotesContainer{
	opacity: 0;
	position: absolute;
	bottom: 30px;
	padding: 50px 40px;
	left: 30px;
	width: 50%;
	font-size: 2.5em;
	background-color: rgba(0, 0, 0, 0.67);
	*{
		color: #fff;
	}
}
.PageLoaded{ 
	.QuotesContainer{
		opacity: 1;
	}
}
.quoteLeft{
	position: absolute;
	font-size: 3em;
	top: -10px;
	left: 30px
}
.quoteRight{
	position: absolute;
	font-size: 3em;
	bottom: -10px;
	right: 30px;
	@include transform(rotate(-180deg));
}

.UppercaseTitle{
	text-transform: none !important;
	padding-bottom: 10px;
}
@media all and (max-width: 930px)  {
	.QuotesContainer{
	font-size: 1.5em; 
	}
}
@media all and (max-width: 700px)  {
	.QuotesContainer{
		text-align: center;
		width: 100%;
		left: 0px;
		bottom: 0px;
		font-size: 1em;
	}
}

.GreenBlock,
.BottomLinkBoxes{
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}	
} 

/*** Cookies ***/
.flex{
  display: flex;
}

.CenterItems{
  display: flex;
  align-items: center;
  justify-content: center;
}
.Cookies{
	display: none;
    background-color: #d32237;
    position: fixed;
    padding: 0px;
    left: 50%;
    bottom: 0px;
    width: 100%;
    z-index: 9999;
    font-size: 1em;
    transform: translateX(-50%);

  *{
    font-size: 1em;
    color: #fff !important;
    font-weight: lighter;
  }
  .inner{
  width: 100%;
    max-width: 1000px;
  }
  a.cookiebtn{
  	text-transform: uppercase;
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
    color: #fff !important;
    @include transition($t1);
    &:hover{
    	background-color: #fff;
    	color: #d32237 !important;
    	border: 1px solid #fff !important;
    }
  }
  .CookiesOk{
    border: 1px solid #fff;
    margin-right: 20px;
  }
  .CookiesDecline{
    border-bottom: 1px solid #fff;
  }
}
.closecookies{
	position: absolute;
	top: 10px;
	right: 10px;
	width: 20px;
	cursor: pointer;
	height: 20px;
	&:before{
		content:'';
		display: block;
		background-color: #fff;
		height: 1px;
		width: 100%;
		left: 50%;
		top: 50%;
		position: absolute;
		@include transform($center rotate(-45deg));
	}
	&:after{
		content:'';
		display: block;
		background-color: #fff;
		height: 1px;
		width: 100%;
		left: 50%;
		top: 50%;
		position: absolute;
		@include transform($center rotate(45deg));
	}
}
/*** Cookies ***/
/* -------------------- Alterations 12/11/2017 -------------------- **/





.error {
    display: inline-block;
    border: 1px solid #da8486;
    margin: 0px 0px 10px 0px;
    padding: 5px 20px;
    font-size: 1em;
    color: #da8486;
    background-color: #ffdfdf;
}
fieldset{
    border: none;
    .field.checkbox { 
        .middleColumn {
            display:inline-block;
        }
    }
}

.PageTemplateTwo{
	.bannerText {
		bottom: 37vh;
	}

	.bannerText h1, .bannerText span , .bannerText p {
		font-size: 3.3vw;
		line-height: 1.3em;
		color: #fff;
		margin-bottom: 0px !important;
	}
}

.PageTemplateTwo .bannerText {
	opacity: 0;
    width: 700px;
    bottom: 100%;
    max-width: 100%;
    @include transition(all 1s);
    *{
    	text-transform: none !important;
    	font-weight: normal !important;
    }
    a{
	text-transform: uppercase !important; 

    	font-weight: lighter !important;
    }
}

.PageLoaded.PageTemplateTwo .bannerText {
    bottom: 12%;
    opacity: 1;
}


.loadingSection{
	position: fixed;
	top: 0px;
	left: 0px;
	background-color: #fff;
	width: 100%;
	z-index: 9999;
	height: 100%;
	img{
		width: 40px;
		top: 50%;
		left: 50%;
		position: absolute;
		@include transform($center);
	}
}
.pace {
	display: none !important;
}
 
